import * as React from 'react';

function FourZeroFour() {
    return (
        <div className="row flex-center text-center">
            <div className="col-12">
                <div className="card">
                    <div className="card-body p-4 p-sm-5">
                        <div className="display-1 text-300 fs-error">404</div>
                        <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">The page you're looking for is not found.</p>
                        <hr />
                        <p>Make sure the address is correct and that the page hasn't moved.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FourZeroFour;
