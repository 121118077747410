import * as React from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';

import Loader from './../../component/common/Loader';
import { onChange, validateForm } from './../../utils';
import CONSTANT from './../../constant';
import { resetPassword } from './../../action/AuthAction';
import { getVendorInfo } from './../../action/VendorAction';

class ResetPassword extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const parsed: any = queryString.parse(props.location.search);
        this.state = {
            uid: parsed.i,
            otp: parsed.t,
            password: { name: 'password', value: '', error: '', isRequired: true },
            cnfPassword: { name: 'cnfPassword', value: '', error: '', isRequired: true },
            showLoader: false
        };
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { password, cnfPassword, showLoader } = this.state;
        return (
            <React.Fragment>
                <div className="container-fluid page-body-wrapper full-page-wrapper login-bg">
                    <div className="row flex-grow justify-content-center">
                        <div className="col-sm-6 loginLeft align-items-center justify-content-center p-0 d-flex">
                            <div className="logo-strip flex-fill">
                                <div className="brand-logo">
                                    <img src="/assets/images/logo_large.png" />
                                    <h2 className="text-white mt-4 mb-0 d-none d-lg-block">The wait is over...</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 loginRight">
                            <form className="justify-content-around" onSubmit={this.submitForm}>
                                <div className="fhead">
                                    <h2 className="text-white flexx-fill">Reset Password</h2>
                                </div>
                                <div className="fbody auth-form-light text-left mt-2 mt-lg-5 text-white">
                                    <div className="form-group over-label password-field mb-5">
                                        <div className="bottom-line">
                                            <input
                                                className={password.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                type="password"
                                                name={password.name}
                                                value={password.value}
                                                onChange={this.onChange}
                                            />
                                            <label className="text-white">Password</label>
                                            <i className="showPassword"></i>
                                        </div>
                                    </div>
                                    <div className="form-group over-label password-field">
                                        <div className="bottom-line">
                                            <input
                                                className={cnfPassword.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                type="password"
                                                name={cnfPassword.name}
                                                value={cnfPassword.value}
                                                onChange={this.onChange}
                                            />
                                            <label className="text-white">Confirm Password</label>
                                            <i className="showPassword"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="ffoot">
                                    <button type="submit" className="btn btn-block btn-primary btn-lg font-weight-bold auth-form-btn mt-3 mt-lg-5">Reset Password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    loadList = () => {
        this.setState({ showLoader: true });
        const { uid } = this.state;
        getVendorInfo(uid).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                this.setState({ email: res.data.email });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false, list: [], count: 0 });
        });
    }

    private submitForm = async (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { uid, otp, password, cnfPassword } = this.state;
            const model = {
                uid: uid,
                otp: otp,
                password: password.value,
                validateType: 'forgot'
            };
            if (password.value !== cnfPassword.value) return toast.error("Password and confirm password doesn't match.");

            this.setState({ showLoader: true });
            resetPassword(model).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 200) {
                    toast.success(res.message);
                    setTimeout(() => {
                        this.props.history.push(`${CONSTANT.url.login}`);
                    }, 1000)
                }
                if (res.status === 400) {
                    toast.error(res.message);
                }
                if (res.status === 500) {
                    toast.error(res.message);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        }
    }
}

export default ResetPassword;