import React from 'react';
import CONSTANT from './../../constant';

const Sidebar = () => {
    return (
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <div className="text-center navbar-brand-wrapper text-center">
                <a className="navbar-brand brand-logo mx-0" href={CONSTANT.url.dashboard}><img
                    src="/assets/images/logo-inner.png" alt="logo" /></a>
                <h5 className="text-white">The wait is over...</h5>
            </div>
            <ul className="nav">
                <li className="nav-item">
                    <a className="nav-link" href={CONSTANT.url.dashboard}>
                        <i className="icon"><img src="/assets/images/icons/dashboard-w.png" /></i>
                        <span className="menu-title">Dashboard</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href={CONSTANT.url.myCatalogues}>
                        <i className="icon"><img src="/assets/images/icons/payment-w.png" /></i>
                        <span className="menu-title">My Catalogues</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href={CONSTANT.url.payments}>
                        <i className="icon"><img src="/assets/images/icons/payment-w.png" /></i>
                        <span className="menu-title">Payments</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href={CONSTANT.url.subscriptions}>
                        <i className="icon"><img src="/assets/images/icons/subscription-w.png" /></i>
                        <span className="menu-title">Subscriptions</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href={CONSTANT.url.logout}>
                        <i className="icon"><img src="/assets/images/icons/logout-w.png" /></i>
                        <span className="menu-title">Logout</span>
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Sidebar;