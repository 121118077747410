import * as React from 'react';
import moment from 'moment';

import Header from './../../component/common/Header';
import Sidebar from './../../component/common/Sidebar';
import Loader from './../../component/common/Loader';
import { getAllSubPlans } from './../../action/SubscriptionAction';
import CONSTANT from '../../constant';

class Subscriptions extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
            list: undefined,
            subscribedPlan: undefined
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { subscribedPlan, list, showLoader } = this.state;
        return (
            <React.Fragment>
                <div className="container-scroller">
                    <Header title='Subscription Plan' />
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar />
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <h6 className="mb-2 pb-2">Current Plan</h6>
                                {subscribedPlan && subscribedPlan.map((subscribedPlanItem: any, subscribedPlanIndex: any) => {
                                    let currentDate = moment();
                                    let prevDate = moment.unix(subscribedPlanItem.endDate);
                                    const diff = prevDate.diff(currentDate, 'days')
                                    return (
                                        <React.Fragment key={subscribedPlanIndex}>
                                            <div className="card xs-shadow border-radius-xs mb-4">
                                                <div className="card-body px-0 py-0">
                                                    <div className="row mx-0 my-0 justify-content-center">
                                                        <div className="px-4 py-3 d-lg-flex align-items-center justify-content-between flex-fill">
                                                            <div className="left">
                                                                <h4>{subscribedPlanItem.name}</h4>
                                                                <div className="d-flex mt-4 mb-3 align-items-end">
                                                                    <div className="clearfix border-right border-black pr-4">
                                                                        <h5 className="text-primary font-weight-600">Validity</h5>
                                                                        <p className="h5 my-0 font-weight-600">{`${moment.unix(subscribedPlanItem.startDate).format("DD MMM, YYYY")} - ${moment.unix(subscribedPlanItem.endDate).format("DD MMM, YYYY")}`}</p>
                                                                    </div>
                                                                    <div className="clearfix d-flex align-items-center pl-4 font-weight-600">
                                                                        <i className="mdi mdi-checkbox-blank-circle text-primary x-small mr-1"></i> {`${subscribedPlanItem.noOfCatalogue} Brochures`}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="right text-center">
                                                                <h2>{subscribedPlanItem.price ? `$${subscribedPlanItem.price}` : subscribedPlanItem.price}</h2>
                                                            </div>
                                                        </div>
                                                        <div className="bg-primary px-4 py-3 py-lg-0 mb-3 mb-lg-0 border-radius-rb-xs">
                                                            <div className="circle-box small-circle m-auto flex-fill text-center">
                                                                <div className="circle-inner">
                                                                    <svg viewBox="0 0 36 36" className="circular-chart dark">
                                                                        <path
                                                                            className="circle-bg"
                                                                            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                                                        />
                                                                        <path className="circle"
                                                                            stroke-dasharray="63, 100"
                                                                            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                                                        />
                                                                    </svg>
                                                                    <div className="text">{`${diff} Day`} <br />Left</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })}

                                {subscribedPlan && subscribedPlan.length == 0 &&
                                    <div className="card xs-shadow border-radius-xs mb-4">
                                        <div className="card-body px-0 py-4">
                                            <div className="row mx-0 my-0 justify-content-center">
                                                <span className="align-middle text-center">No Current Plan</span>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="row mb-4">
                                    {list && list.map((listItem: any, listIndex: any) => {
                                        return (
                                            <React.Fragment key={listIndex}>
                                                <div className="col-sm-4 mb-4 mb-lg-0">
                                                    <div className="card lg-shadow border-radius-br-10 text-center card-pattern">
                                                        <div className="card-body px-2 py-3 pb-4">
                                                            <h1>{listItem.price ? `$${listItem.price}` : listItem.price}</h1>
                                                            <h4>{listItem.name}</h4>
                                                            <h4 className="text-primary mb-3">Validity</h4>
                                                            <h5>{`${moment(listItem.fromDate).format("DD MMM, YYYY")} - ${moment(listItem.toDate).format("DD MMM, YYYY")}`}</h5>
                                                            <div className="clearfix d-flex align-items-center justify-content-center mt-3 mb-4 font-weight-600">
                                                                <i className="mdi mdi-checkbox-blank-circle text-primary x-small mr-1"></i> {`${listItem.noOfCatalogue} Brochures`}
                                                            </div>
                                                            <h5 className="font-weight-600 mb-2 pb-1">Terms &amp; Conditions</h5>
                                                            <p className="mb-4 text-gray">{listItem.terms}</p>
                                                            <button type="button" onClick={() => this.onBuy(listItem.id)} className="btn btn-primary col-sm-8 m-auto">Buy Now</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            </div>
                            <div id="mask"></div>
                            <footer className="footer">
                                <div className="text-center text-muted">
                                    Copyright &copy; 2020 <span className="text-default">Studbooks</span>. All rights reserved.
						        </div>
                            </footer>
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment>
        )
    }

    loadList = () => {
        this.setState({ showLoader: true });
        getAllSubPlans().then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                this.setState({
                    list: res.data.allPlan,
                    subscribedPlan: res.data.subscribedPlan
                });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false, list: [], subscribedPlan: 0, });
        });
    }

    onBuy = (pid: any) => {
        this.props.history.push(`${CONSTANT.url.checkout}?pid=${pid}`);
    }

}

export default Subscriptions;