import * as React from 'react';
import ReactDOM from 'react-dom';
import queryString, { parse } from 'query-string';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Loader from './../../component/common/Loader';
import { getVendorPlanDetail, getCouponDetail, applyCoupon, checkoutSession } from './../../action/PlanAction';
import { createFreeSubscription, createSubscription } from './../../action/PurchaseAction';
import { onChange, validateForm, getStorage } from '../../utils';
import CONSTANT from '../../constant';
import CONFIG from './../../config';
import { loadStripe } from '@stripe/stripe-js';
const defaultProfile = require('./../../assets/images/default.jpg');

const stripePromise = loadStripe(CONFIG.stripe.publishKey);

class Checkout extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        const parsed: any = queryString.parse(props.location.search);
        this.state = {
            planId: parsed.pid,
            couponId: parsed.c,
            showLoader: false,
            detail: undefined,
            name: '',
            photoURL: '',
            subTotal: undefined,
            tax: undefined,
            discount: undefined,
            amountToPaid: undefined,
            couponCode: { name: 'couponCode', value: '', error: '', isRequired: true },
            couponDetail: undefined,
            isCouponApplied: false,
            applyCouponDetail: undefined
        }
    }

    async componentDidMount() {
        const { planId, couponId } = this.state;
        const ud = getStorage(CONSTANT.keys.userDetail);
        this.setState({ name: ud.displayName, photoURL: ud.photoURL });
        await this.loadList();
    }

    public render() {
        const { showLoader, planId, couponCode, detail, name, photoURL, subTotal, tax, discount, amountToPaid, isCouponApplied } = this.state;
        return (
            <React.Fragment>
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper bg-white">
                            <div className="profile-header pt-3">
                                <div className="header-bg -lg" style={{ "backgroundImage": "url('/assets/images/profile-header.png')" }}></div>
                            </div>
                            <div className="position-relative z-index-11">
                                <div className="col-sm-8 m-auto d-flex align-items-center justify-content-between">
                                    <a className="navbar-brand brand-logo mx-0 text-center" href="#">
                                        <img src="/assets/images/logo-small.png" alt="logo" />
                                        <h5 className="text-white mt-2">The wait is over...</h5>
                                    </a>
                                    <ul className="navbar-nav navbar-nav-right">
                                        <li className="nav-item nav-profile dropdown">
                                            <a href="" className="nav-link bg-transparent d-flex align-items-center" id="profileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <div className="nav-profile-img mr-3">
                                                    {photoURL ?
                                                        <img src={photoURL} alt="image" />
                                                        : <img src={defaultProfile} alt="image" />}
                                                </div>
                                                <div className="nav-profile-text">
                                                    <p className="mb-1">{name}</p>
                                                </div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="profileDropdown">
                                                <a href={CONSTANT.url.logout} className="dropdown-item">Logout</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-8 ml-auto mr-auto pt-2">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-2">
                                            <li className="breadcrumb-item font-weight-300"><a href={CONSTANT.url.subscriptions}>Buy Product</a></li>
                                            <li className="breadcrumb-item font-weight-300 active text-white" aria-current="page">
                                                Payment Details</li>
                                        </ol>
                                    </nav>
                                    <div className="card no-shadow">
                                        <div className="d-lg-flex align-items-center justify-content-between px-2 px-lg-4 py-3 py-lg-4">
                                            <div className="left px-2">
                                                <h4 className="font-weight-600">{detail && detail.name}</h4>
                                                <div className="clearfix d-flex align-items-center h6 font-weight-300 mt-2 mb-3">
                                                    <i className="mdi mdi-checkbox-blank-circle text-primary x-small mr-2"></i> 1
                                                    Brochures
                                                </div>
                                                <p className="h6 font-weight-600 mb-0">Terms & Conditions</p>
                                                <p className="my-0 text-gray font-weight-400">{detail && detail.terms ? detail.terms : ''}</p>
                                            </div>
                                            <div className="right text-lg-right text-primary px-2 mt-3 mt-lg-0">
                                                <h2 className="my-0">{`$${detail && detail.price ? detail.price : ''}`}</h2>
                                                <p>{''}</p>
                                            </div>
                                        </div>
                                        {detail && !detail.isFree &&
                                            <div className="d-flex flex-column px-4 py-4 bg-light-gray">
                                                <h6>Coupon Code</h6>
                                                <form className="form-inline d-block">
                                                    <div className="d-lg-flex mb-3">
                                                        <div className="d-flex align-items-center flex-fill bg-white pr-3">
                                                            <input
                                                                type="text"
                                                                className="form-control control-lg no-border mx-sm-2 font-weight-300 flex-fill"
                                                                placeholder="Add Coupon Code"
                                                                name={couponCode.name}
                                                                value={couponCode.value}
                                                                onChange={this.onChange}
                                                            />
                                                            <a href={`${CONSTANT.url.coupon}?p=${planId}`} className="text-primary text-decoration h6 my-0 no-wrap">
                                                                <b>View Coupon</b>
                                                            </a>
                                                        </div>
                                                        <button type="button" className="btn btn-primary py-2 d-block d-lg-flex align-items-center justify-content-center col-sm-3 mt-2 mt-lg-0" disabled={isCouponApplied} onClick={this.onDiscountApply}>
                                                            <h4 className="mb-1 px-4">Apply</h4>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        }
                                        <div className="data-total mt-3">
                                            <div className="d-flex py-2 mb-1">
                                                <div>Subtotal</div>
                                                <div className="flex-fill dotted-line mx-3"></div>
                                                <div>{`$${subTotal ? subTotal : ''}`}</div>
                                            </div>
                                            <div className="d-flex py-2 mb-1">
                                                <div>Estimated Tax</div>
                                                <div className="flex-fill dotted-line mx-3"></div>
                                                <div>{`$${tax ? tax : '0'}`}</div>
                                            </div>
                                            <div className="d-flex py-2 mb-1">
                                                <div>Discount Applied</div>
                                                <div className="flex-fill dotted-line mx-3"></div>
                                                <div>{`$${discount ? discount : '0'}`}</div>
                                            </div>
                                            <div className="d-flex py-2">
                                                <div className="font-weight-600">Total amount to be paid</div>
                                                <div className="flex-fill dotted-line mx-3"></div>
                                                <div className="font-weight-600">{`$${amountToPaid ? amountToPaid : ''}`}</div>
                                            </div>
                                        </div>
                                        <div className="clearfix text-right my-4">
                                            <button className="btn btn-primary py-2 col-lg-4 d-lg-inline" onClick={this.onCheckout}>
                                                <h4 className="mb-1">Checkout</h4>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="mask"></div>
                        <footer className="footer">
                            <div className="text-center text-muted">
                                Copyright &copy; 2020 <span className="text-default">Studbooks</span>. All rights reserved.
                            </div>
                        </footer>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
        this.setState({ isCouponApplied: false });
    }

    loadList = () => {
        const { planId, couponId } = this.state;

        this.setState({ showLoader: true });
        getVendorPlanDetail(planId).then((res: any) => {
            if (res.status === 200) {
                this.setState({
                    showLoader: false,
                    detail: res.data,
                    subTotal: res.data.price,
                    tax: 0,
                    discount: 0,
                    amountToPaid: res.data.price
                });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });

        if (couponId) {
            this.setState({ showLoader: true });
            getCouponDetail(couponId).then((res: any) => {
                if (res.status === 200) {
                    this.setState({
                        showLoader: false,
                        couponDetail: res.data,
                    });
                    onChange(this, this.state.couponCode.name, res.data.code);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        }
    }

    onDiscountApply = () => {
        const { detail, subTotal, tax, discount, amountToPaid } = this.state;
        const model = {
            subscriptionId: this.state.planId,
            code: this.state.couponCode.value
        }
        this.setState({ showLoader: true });
        applyCoupon(model).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                toast.success("Coupon applied successfully");
                let discount = 0;
                if (res.data.isFixedDiscount) {
                    discount = res.data.amount;
                } else {
                    let dis = (parseFloat(detail.price) * parseFloat(res.data.percentage) / 100).toFixed(2);
                    discount = Number(dis);
                }
                let netAmount = (detail.price - discount).toFixed(2);
                this.setState({
                    tax: 0,
                    discount,
                    amountToPaid: Number(netAmount),
                    isCouponApplied: true,
                    applyCouponDetail: res.data
                });
            }
            if (res.status === 400) {
                toast.error(res.message);
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    onCheckout = async (event: any) => {
        const { detail, isCouponApplied, couponCode, applyCouponDetail } = this.state;

        let objModel: any = {
            planId: detail.planId,
        }
        if (isCouponApplied) {
            objModel.couponCode = applyCouponDetail.stripeCouponId;
        }

        this.setState({ showLoader: true });
        const subModel = {
            isFree: detail.isFree
        }
        const subResult = await createSubscription(subModel);
        if (subResult.status === 200) {
            if (detail.isFree) {
                this.setState({ showLoader: true });
                createFreeSubscription({ planId: detail.planId }).then((res: any) => {
                    this.setState({ showLoader: false });
                    if (res.status === 200) {
                        this.props.history.push(CONSTANT.url.dashboard);
                    }
                    if (res.status === 400) {
                        toast.error(res.message);
                    }
                }).catch((error: any) => {
                    this.setState({ showLoader: false });
                });
            } else {
                this.setState({ showLoader: true });
                const session = await checkoutSession(objModel);
                const stripe: any = await stripePromise;
                this.setState({ showLoader: false });
                if (session) {
                    this.setState({ showLoader: false });
                    const { error } = await stripe.redirectToCheckout({
                        sessionId: session.data.id,
                    });
                }
            }
        }
        if (subResult.status === 400) {
            this.setState({ showLoader: false });
            toast.error(subResult.message);
        }
    };
}

export default Checkout;