import React from 'react';

const ComponentName = () => {
    const dt = new Date();
    return (
        <footer className="footer">
            <div className="text-center text-muted">
                Copyright &copy; {dt.getFullYear()} <span className="text-default">Studbooks</span>. All rights reserved.
            </div>
        </footer>
    )
}

export default ComponentName;