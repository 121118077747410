import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

import Header from './../component/common/Header';
import Sidebar from './../component/common/Sidebar';
import Footer from './../component/common/Footer';
import Loader from './../component/common/Loader';
import { getAllStats, getRatingGraphStats, getViewGraphStats, getDownloadsGraphStats } from './../action/DashboardAction';

class Dashboard extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            date: moment(),
            showLoader: false,
            detail: undefined,
            filterType: { name: 'filterType', value: 'monthly', error: '', isRequired: false },
            ratingChartStats: undefined,
            viewChartStats: undefined,
            downloadChartStats: undefined
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader, detail, filterType, ratingChartStats, viewChartStats, downloadChartStats } = this.state;
        return (
            <React.Fragment>
                <div className="container-scroller">
                    <Header title='Dashboard' />
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar />
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-md-4 stretch-card grid-margin">
                                        <div className="card card-img-holder text-default text-center">
                                            <div className="clearfix pt-5 pb-4 mb-2">
                                                <div className="icon mb-45 mt-2">{detail && detail.totalCatalogues ? detail.totalCatalogues : '0'}</div>
                                                <img src="/assets/images/dashboard/total-catalogue.png" className="card-img-absolute"
                                                    alt="total catalogue" />
                                                <h4 className="my-0">Total Catalogues <br />Uploaded</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 stretch-card grid-margin">
                                        <div className="card card-img-holder text-default text-center">
                                            <div className="clearfix pt-5 pb-4 mb-2">
                                                <div className="icon mb-45 mt-2">{detail && detail.totalUpdates ? detail.totalUpdates : '0'}</div>
                                                <img src="/assets/images/dashboard/announcements.png" className="card-img-absolute"
                                                    alt="updates announcements" />
                                                <h4 className="my-0">Updates <br />Announcements List</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 stretch-card grid-margin">
                                        <div className="card card-img-holder text-default pl-4 py-3 d-flex">
                                            <h5>Overall Catalogue Rating</h5>
                                            <div className="clearfix d-flex align-items-center px-lg-3 flex-fill">
                                                <div className="circle-box mr-2 mr-lg-4 flex-fill text-center">
                                                    <div className="circle-inner">
                                                        <svg viewBox="0 0 36 36" className="circular-chart default">
                                                            <path className="circle-bg" d="M18 2.0845
														a 15.9155 15.9155 0 0 1 0 31.831
														a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                            <path className="circle" stroke-dasharray="63, 100" d="M18 2.0845
														a 15.9155 15.9155 0 0 1 0 31.831
														a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        </svg>
                                                        <div className="text">{detail && detail.avgRating ? detail.avgRating?.toFixed(2) : '0'} <br />Ratings</div>
                                                    </div>
                                                </div>
                                                <div className="star-list mr-5 mr-md-0">
                                                    <h5 className="mb-1 mb-lg-3">{detail && detail.totalDownload ? detail.totalDownload : '0'} Download</h5>
                                                    <ul>
                                                        <li><i className="mdi mdi-star text-warning"></i> 15/70</li>
                                                        <li><i className="mdi mdi-star text-warning"></i> 15/70</li>
                                                        <li><i className="mdi mdi-star text-warning"></i> 15/70</li>
                                                        <li><i className="mdi mdi-star text-warning"></i> 15/70</li>
                                                        <li><i className="mdi mdi-star text-warning"></i> 15/70</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 stretch-card grid-margin">
                                        <div className="card card-img-holder chart-tabs px-2 py-2">
                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="tab1" data-toggle="tab" href="#averageRatings"
                                                        role="tab" aria-controls="averageRatings" aria-selected="true">Average
                                                                        Ratings</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="tab2" data-toggle="tab" href="#averageViews" role="tab"
                                                        aria-controls="averageViews" aria-selected="false">Average Views</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="tab3" data-toggle="tab" href="#averageDownloads"
                                                        role="tab" aria-controls="averageDownloads" aria-selected="false">Average
                                                                        Downloads</a>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="averageRatings" role="tabpanel"
                                                    aria-labelledby="tab1">
                                                    <div className="filter">
                                                        <label>Filter</label>
                                                        <select
                                                            name={filterType.name}
                                                            value={filterType.value}
                                                            onChange={this.onChange}>
                                                            <option value="monthly">Montly</option>
                                                            <option value="yearly">Yearly</option>
                                                        </select>
                                                    </div>
                                                    {ratingChartStats && this.loadRatingChart()}
                                                </div>
                                                <div className="tab-pane fade show" id="averageViews" role="tabpanel"
                                                    aria-labelledby="tab2">
                                                    <div className="filter">
                                                        <label>Filter</label>
                                                        <select
                                                            name={filterType.name}
                                                            value={filterType.value}
                                                            onChange={this.onChange}>
                                                            <option value="monthly">Montly</option>
                                                            <option value="yearly">Yearly</option>
                                                        </select>
                                                    </div>
                                                    {viewChartStats && this.loadViewsChart()}
                                                </div>
                                                <div className="tab-pane fade" id="averageDownloads" role="tabpanel"
                                                    aria-labelledby="tab3">
                                                    <div className="filter">
                                                        <label>Filter</label>
                                                        <select
                                                            name={filterType.name}
                                                            value={filterType.value}
                                                            onChange={this.onChange}>
                                                            <option value="monthly">Montly</option>
                                                            <option value="yearly">Yearly</option>
                                                        </select>
                                                    </div>
                                                    {downloadChartStats && this.loadDownloadChart()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="mask"></div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;

        this.setState({
            filterType: { ...this.state.filterType, value: value }
        }, () => {
            this.loadList();
        });
    }

    loadList = async () => {
        const { date, filterType } = this.state;
        this.setState({ showLoader: true });
        const model = {
            date: date.format("DD-MM-YYYY"),
            filterType: filterType.value
        }
        const allStats = await getAllStats();
        const allRatingStats = await getRatingGraphStats(model);
        const allViewStats = await getViewGraphStats(model);
        const allDownloadsStats = await getDownloadsGraphStats(model);

        if (allStats.status === 200) {
            this.setState({ detail: allStats.data });
        }

        if (allRatingStats.status === 200) {
            this.setState({ ratingChartStats: allRatingStats.data });
        }

        if (allViewStats.status === 200) {
            this.setState({ viewChartStats: allViewStats.data });
        }

        if (allDownloadsStats.status === 200) {
            this.setState({ downloadChartStats: allDownloadsStats.data });
        }

        this.setState({ showLoader: false });
    }

    loadRatingChart = () => {
        const { date, filterType, ratingChartStats } = this.state;
        let chartOptions: any;
        if (filterType.value === 'monthly') {

            let xAxisData = ratingChartStats.map((i: any, index: any) => {
                return index + 1;
            });
            let seriesData = ratingChartStats.map((i: any) => {
                return i.avgRating;
            });

            chartOptions = {
                chart: {
                    type: 'spline'
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: xAxisData,
                },
                yAxis: {
                    title: null
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    spline: {
                        marker: {
                            radius: 6,
                            lineColor: '#000',
                            lineWidth: 3
                        }
                    }
                },
                series: [{
                    name: 'Ratings',
                    data: seriesData,
                    shadow: true,
                    color: '#43bcb2'
                }]
            };
        } else {
            let seriesData = ratingChartStats.map((i: any) => {
                return i.avgRating;
            });
            chartOptions = {
                chart: {
                    type: 'spline'
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                },
                yAxis: {
                    title: null
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    spline: {
                        marker: {
                            radius: 6,
                            lineColor: '#000',
                            lineWidth: 3
                        }
                    }
                },
                series: [{
                    name: 'Ratings',
                    data: seriesData,
                    shadow: true,
                    color: '#43bcb2'
                }]
            };
        }

        return (
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                />
            </div>
        )
    }

    loadViewsChart = () => {
        const { date, filterType, viewChartStats } = this.state;
        let chartOptions: any;
        if (filterType.value === 'monthly') {

            let xAxisData = viewChartStats.map((i: any, index: any) => {
                return index + 1;
            });
            let seriesData = viewChartStats.map((i: any) => {
                return i.avgView;
            });

            chartOptions = {
                chart: {
                    type: 'spline'
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: xAxisData,
                },
                yAxis: {
                    title: null
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    spline: {
                        marker: {
                            radius: 6,
                            lineColor: '#000',
                            lineWidth: 3
                        }
                    }
                },
                series: [{
                    name: 'Views',
                    data: seriesData,
                    shadow: true,
                    color: '#43bcb2'
                }]
            };
        } else {
            let seriesData = viewChartStats.map((i: any) => {
                return i.avgView;
            });
            chartOptions = {
                chart: {
                    type: 'spline'
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                },
                yAxis: {
                    title: null
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    spline: {
                        marker: {
                            radius: 6,
                            lineColor: '#000',
                            lineWidth: 3
                        }
                    }
                },
                series: [{
                    name: 'Views',
                    data: seriesData,
                    shadow: true,
                    color: '#43bcb2'
                }]
            };
        }

        return (
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                />
            </div>
        )
    }

    loadDownloadChart = () => {
        const { date, filterType, downloadChartStats } = this.state;
        let chartOptions: any;
        if (filterType.value === 'monthly') {

            let xAxisData = downloadChartStats.map((i: any, index: any) => {
                return index + 1;
            });
            let seriesData = downloadChartStats.map((i: any) => {
                return i.avgDownload;
            });

            chartOptions = {
                chart: {
                    type: 'spline'
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: xAxisData,
                },
                yAxis: {
                    title: null
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    spline: {
                        marker: {
                            radius: 6,
                            lineColor: '#000',
                            lineWidth: 3
                        }
                    }
                },
                series: [{
                    name: 'Downloads',
                    data: seriesData,
                    shadow: true,
                    color: '#43bcb2'
                }]
            };
        } else {
            let seriesData = downloadChartStats.map((i: any) => {
                return i.avgDownload;
            });
            chartOptions = {
                chart: {
                    type: 'spline'
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                },
                yAxis: {
                    title: null
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    spline: {
                        marker: {
                            radius: 6,
                            lineColor: '#000',
                            lineWidth: 3
                        }
                    }
                },
                series: [{
                    name: 'Downloads',
                    data: seriesData,
                    shadow: true,
                    color: '#43bcb2'
                }]
            };
        }

        return (
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                />
            </div>
        )
    }
}

export default Dashboard;
