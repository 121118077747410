import * as React from 'react';
import Spinner from './../../assets/images/spinner.png';

const Loader = () => {
    return (
        <div className="col d-flex align-items-center justify-content-center loader-container ">
            <img alt="Loading..." className='loader' src={Spinner} />
        </div>
    );
}

export default Loader;