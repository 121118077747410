export default {
    firebase: {
        API_KEY: "AIzaSyA7FfPA8z9rbT_eZrRTQyFqoUTjPTjFrqQ",
        AUTH_DOMAIN: "studbook-app.firebaseapp.com",
        DATABASE_URL: "https://studbook-app.firebaseio.com",
        PROJECT_ID: "studbook-app",
        STORAGE_BUCKET: "studbook-app.appspot.com",
        MESSAGING_SENDER_ID: "255797793793",
        APP_ID: "1:255797793793:web:81ce1aec88b6bb85ef9c00",
        MEASUREMENT_ID: "G-CFFT86Z7FX"
    },
    stripe: {
        publishKey: "pk_live_51HOxneCeCTQrPYiQwUnQXa8Yt7XH8Ff30rdqOsckYKd3XmIbbxGqjID5Exf1yxxjZ600MoeBkPtRjputri8gTe7800RuaooV8T"
    },
    placeAPIKey: "AIzaSyBlc8h47A8FNVIEfpBivVobmg-NNtBxVPE"
}