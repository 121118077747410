import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './../../component/common/Header';
import Sidebar from './../../component/common/Sidebar';
import Loader from '../../component/common/Loader';
import AddCatalogueDetail from './../../component/catalogue/Add';
import { updateCatalogueDetail, getCatalogueDetail } from './../../action/CatalogueAction';
import CONSTANT from '../../constant';

class EditCatalogue extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            catalogueId: props.match.params.id,
            detail: undefined,
            showLoader: false,
        }
    }

    componentDidMount() {
        this.setState({ showLoader: true });
        getCatalogueDetail(this.state.catalogueId).then((res: any) => {
            if (res.status === 200) {
                this.setState({
                    showLoader: false,
                    detail: res.data,
                });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    public render() {
        const { showLoader, catalogueId, detail } = this.state;
        const url = CONSTANT.url.catalogueEdit.replace(':id', catalogueId);
        return (
            <React.Fragment>
                <div className="container-scroller">
                    <Header title='My Catalogues' />
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar />
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href={CONSTANT.url.myCatalogues}>My Catalogues</a></li>
                                        {detail && detail.title &&
                                            <li className="breadcrumb-item active"><a href={url}>{detail && detail.title}</a></li>
                                        }
                                    </ol>
                                </nav>
                                {detail &&
                                    <AddCatalogueDetail
                                        onSubmit={this.onSubmit}
                                        id={catalogueId}
                                        detail={detail}
                                    />
                                }
                            </div>
                            <div id="mask"></div>
                            <footer className="footer">
                                <div className="text-center text-muted">
                                    Copyright &copy; 2020 <span className="text-default">Studbooks</span>. All rights reserved.
					            </div>
                            </footer>
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    onSubmit = (model: any) => {
        this.setState({ showLoader: true });
        updateCatalogueDetail(model, model.id).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                toast.success("Catalogue successfully updated!");
                this.props.history.push(CONSTANT.url.myCatalogues);
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }
}

export default EditCatalogue;