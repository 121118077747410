import * as React from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NumberFormat from 'react-number-format';
import $ from 'jquery';

import Loader from './../../component/common/Loader';
import { onChange, validateForm } from './../../utils';
import CONSTANT from './../../constant';
import { registerUser } from './../../action/AuthAction';
import TermCondition from './../../component/common/TermCondition';
import ModalWindow from '../../component/common/ModalWindow'

class Login extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            firstName: { name: 'firstName', value: '', error: '', isRequired: true },
            lastName: { name: 'lastName', value: '', error: '', isRequired: true },
            studName: { name: 'studName', value: '', error: '', isRequired: true },
            tradingName: { name: 'tradingName', value: '', error: '', isRequired: true },
            email: { name: 'email', value: '', error: '', isRequired: true },
            countryCode: { name: 'countryCode', value: '', error: '', isRequired: false },
            phone: { name: 'phone', value: '', error: '', isRequired: true },
            address: { name: 'address', value: '', error: '', isRequired: true },
            website: { name: 'website', value: '', error: '', isRequired: false },
            password: { name: 'password', value: '', error: '', isRequired: true },
            cnfpassword: { name: 'cnfpassword', value: '', error: '', isRequired: true },
            agreeTerm: { name: 'agreeTerm', value: '', error: '', isRequired: true },
            showLoader: false
        }
    }

    public render() {
        const { firstName, lastName, studName, tradingName, email, phone, address, website, password, cnfpassword, agreeTerm, showLoader, showModal } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper full-page-wrapper login-bg">
                        <div className="row flex-grow justify-content-center">
                            <div className="col-sm-6 loginLeft align-items-center justify-content-center p-0 d-flex">
                                <div className="logo-strip flex-fill">
                                    <div className="brand-logo">
                                        <img src="/assets/images/logo_large.png" />
                                        <h2 className="text-white mt-4 mb-0 d-none d-lg-block">The wait is over...</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 loginRight text-white">
                                <form className="justify-content-around">
                                    <div className="fhead d-flex justify-content-between align-items-center">
                                        <h2 className="flex-fill">Register Now</h2>
                                        <div className="steps">
                                            <ul>
                                                <li className="active">1</li>
                                                <li>2</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="fbody auth-form-light text-left mt-lg-5 active">
                                        <div className="row">
                                            <div className="form-group over-label col-sm-6 mb-4 mb-lg-5">
                                                <div className="position-relative bottom-line">
                                                    <input
                                                        className={firstName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                        type="text"
                                                        name={firstName.name}
                                                        value={firstName.value}
                                                        onChange={this.onChange}
                                                    />
                                                    <label className="text-muted">First Name</label>
                                                </div>
                                            </div>
                                            <div className="form-group over-label col-sm-6 mb-4 mb-lg-5">
                                                <div className="position-relative bottom-line">
                                                    <input
                                                        className={lastName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                        type="text"
                                                        name={lastName.name}
                                                        value={lastName.value}
                                                        onChange={this.onChange}
                                                    />
                                                    <label className="text-muted">Last Name</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group over-label mb-4 mb-lg-5">
                                            <div className="bottom-line">
                                                <input
                                                    className={studName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="text"
                                                    name={studName.name}
                                                    value={studName.value}
                                                    onChange={this.onChange}
                                                />
                                                <label className="text-muted">Stud Name</label>
                                            </div>
                                        </div>
                                        <div className="form-group over-label mb-4 mb-lg-5">
                                            <div className="bottom-line">
                                                <input
                                                    className={tradingName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="text"
                                                    name={tradingName.name}
                                                    value={tradingName.value}
                                                    onChange={this.onChange}
                                                />
                                                <label className="text-muted">Trading Name</label>
                                            </div>
                                        </div>
                                        <div className="form-group over-label mb-4 mb-lg-5">
                                            <div className="bottom-line">
                                                <input
                                                    className={email.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="email"
                                                    name={email.name}
                                                    value={email.value}
                                                    onChange={this.onChange}
                                                />
                                                <label className="text-muted">Email</label>
                                            </div>
                                        </div>
                                        <div className="form-group over-label mb-4 mb-lg-5">
                                            <div className="bottom-line">
                                                <NumberFormat
                                                    className={phone.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    name={phone.name}
                                                    maxLength={10}
                                                    allowNegative={false}
                                                    value={phone.value}
                                                    allowLeadingZeros={true}
                                                    onChange={this.onChange}
                                                />
                                                <label className="text-muted">Phone Number</label>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-sm-12">
                                                <button type="button" className="btn btn-primary btn-block regNext" onClick={this.onNext}>Next</button>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <p className="mb-0 h4 text-white">Already have an account? <Link className="text-primary" to={CONSTANT.url.login}> Login</Link></p>
                                        </div>
                                    </div>
                                    <div className="fbody auth-form-light text-left mt-lg-5" style={{ "display": "none" }}>
                                        <div className="form-group over-label mb-4 mb-lg-5">
                                            <div className="bottom-line">
                                                <input
                                                    className={address.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="text"
                                                    name={address.name}
                                                    value={address.value}
                                                    onChange={this.onChange}
                                                />
                                                <label className="text-muted">Address</label>
                                            </div>
                                        </div>
                                        {/* <div className="form-group over-label mb-4 mb-lg-5">
                                            <div className="bottom-line">
                                                <input
                                                    className={website.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="text"
                                                    name={website.name}
                                                    value={website.value}
                                                    onChange={this.onChange}
                                                />
                                                <label className="text-muted">Website</label>
                                            </div>
                                        </div> */}
                                        <div className="form-group over-label password-field mb-4 mb-lg-5">
                                            <div className="bottom-line">
                                                <input
                                                    className={password.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="password"
                                                    name={password.name}
                                                    value={password.value}
                                                    onChange={this.onChange}
                                                />
                                                <label className="text-muted">Password</label>
                                                <i className="showPassword text-white"></i>
                                            </div>
                                        </div>
                                        <div className="form-group over-label password-field mb-4">
                                            <div className="bottom-line">
                                                <input
                                                    className={cnfpassword.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="password"
                                                    name={cnfpassword.name}
                                                    value={cnfpassword.value}
                                                    onChange={this.onChange}
                                                />
                                                <label className="text-muted">Confirm Password</label>
                                                <i className="showPassword text-white"></i>
                                            </div>
                                        </div>
                                        <div className="my-2 d-flex justify-content-between align-items-center">
                                            <div className="form-check lg">
                                                <label className="form-check-label text-white font-weight-600">
                                                    <input type="checkbox" className="form-check-input" name={agreeTerm.name} onChange={this.onAgreeTerm} checked={agreeTerm.value} />
                                                    <i className="input-helper"></i> I agree to <a href="#" className="text-primary" onClick={this.onShowModal}>Terms &amp; Conditions</a>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mt-4 mt-lg-5">
                                            <div className="col-6 col-sm-6">
                                                <button type="button" className="btn btn-default btn-block regPrev" onClick={this.onPrevious}>Previous</button>
                                            </div>
                                            <div className="col-6 col-sm-6">
                                                <button type="button" className="btn btn-primary btn-block" onClick={this.onRegister}>Register</button>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <p className="mb-0 h4 text-white">Already have an account?<Link className="text-primary" to={CONSTANT.url.login}> Login</Link></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    showModal &&
                    <ModalWindow
                        title="Terms & Conditions"
                        backdrop="static"
                        className="modal-lg modal-dialog-scrollable"
                        toggleModal={this.onHideModal}>
                        <TermCondition />
                    </ModalWindow>
                }
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    private onAgreeTerm = (e: any) => {
        const name = e.target.name;
        const value = e.target.checked;

        onChange(this, name, value);
    }

    onShowModal = () => {
        this.setState({ showModal: true })
    }

    onHideModal = () => {
        this.setState({ showModal: false })
    }

    vaidateFormOne = () => {
        const { firstName, lastName, studName, tradingName, email, phone } = this.state;
        let isValid = true;
        if (firstName.isRequired && firstName.value.length == 0) {
            isValid = false;
            this.setState({
                firstName: {
                    ...this.state.firstName,
                    error: 'This field is required'
                }
            })
        }
        if (lastName.isRequired && lastName.value.length == 0) {
            isValid = false;
            this.setState({
                lastName: {
                    ...this.state.lastName,
                    error: 'This field is required'
                }
            })
        }
        if (studName.isRequired && studName.value.length == 0) {
            isValid = false;
            this.setState({
                studName: {
                    ...this.state.studName,
                    error: 'This field is required'
                }
            })
        }
        if (tradingName.isRequired && tradingName.value.length == 0) {
            isValid = false;
            this.setState({
                tradingName: {
                    ...this.state.tradingName,
                    error: 'This field is required'
                }
            })
        }
        if (email.isRequired && email.value.length == 0) {
            isValid = false;
            this.setState({
                email: {
                    ...this.state.email,
                    error: 'This field is required'
                }
            })
        }
        if (phone.isRequired && phone.value.length == 0) {
            isValid = false;
            this.setState({
                phone: {
                    ...this.state.phone,
                    error: 'This field is required'
                }
            })
        }
        return isValid;
    }

    vaidateFormTwo = () => {
        const { address, website, password, cnfpassword } = this.state;
        let isValid = true;
        if (address.isRequired && address.value.length == 0) {
            isValid = false;
            this.setState({
                address: {
                    ...this.state.address,
                    error: 'This field is required'
                }
            })
        }
        if (password.isRequired && password.value.length == 0) {
            isValid = false;
            this.setState({
                password: {
                    ...this.state.password,
                    error: 'This field is required'
                }
            })
        }
        if (cnfpassword.isRequired && cnfpassword.value.length == 0) {
            isValid = false;
            this.setState({
                cnfpassword: {
                    ...this.state.cnfpassword,
                    error: 'This field is required'
                }
            })
        }
        return isValid;
    }

    onNext = () => {
        if (this.vaidateFormOne()) {
            $('.fbody.active').slideUp().removeClass('active').next().addClass('active').slideDown();
            $('.loginRight .steps li.active').removeClass('active').next().addClass('active');
        }
    }

    onPrevious = () => {
        $('.fbody.active').slideUp().removeClass('active').prev().addClass('active').slideDown();
        $('.loginRight .steps li.active').removeClass('active').prev().addClass('active');
    }

    onRegister = () => {
        if (this.vaidateFormTwo()) {
            const st = this.state;

            if (st.password.value !== st.cnfpassword.value) return toast.error("Password and confirm password doesn't match.");
            if (!st.agreeTerm.value) return toast.error("Please agree terms and constions.");

            const model = {
                firstName: st.firstName.value,
                lastName: st.lastName.value,
                studName: st.studName.value,
                tradingName: st.tradingName.value,
                email: st.email.value,
                phone: st.phone.value,
                address: st.address.value,
                website: st.website.value,
                password: st.password.value,
                cnfpassword: st.cnfpassword.value,
                role: 'vendor'
            };
            this.setState({ showLoader: true });
            registerUser(model).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 201) {
                    window.location.href = `${CONSTANT.url.registerVerification}?i=${res.data.uid}`;
                }
                if (res.status === 400) {
                    toast.error(res.message);
                }
                if (res.status === 500) {
                    toast.error(res.message);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        }
    }

    private submitForm = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const st = this.state;
            const model = {
                firstName: st.firstName.value,
                lastName: st.lastName.value,
                studName: st.studName.value,
                tradingName: st.tradingName.value,
                email: st.email.value,
                countryCode: st.countryCode.value,
                phone: st.phone.value,
                address: st.address.value,
                website: st.website.value,
                password: st.password.value,
                cnfpassword: st.cnfpassword.value,
                role: 'vendor'
            };
            this.setState({ showLoader: true });
            registerUser(model).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 201) {
                    this.props.history.push(CONSTANT.url.dashboard);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
                toast.error(error.data.message);
            });
        }
    }
}

export default Login;