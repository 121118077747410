import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Loader from './../../component/common/Loader';
import { onChange, validateForm } from './../../utils';
import CONSTANT from './../../constant';
import { forgotPassword } from './../../action/AuthAction';

class ForgotPassword extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: { name: 'email', value: '', error: '', isRequired: true },
            showLoader: false
        };
    }

    public render() {
        const { email, showLoader } = this.state;
        return (
            <React.Fragment>
                <div className="container-fluid page-body-wrapper full-page-wrapper login-bg">
                    <div className="row flex-grow justify-content-center">
                        <div className="col-sm-6 loginLeft align-items-center justify-content-center p-0 d-flex">
                            <div className="logo-strip flex-fill">
                                <div className="brand-logo">
                                    <img src="/assets/images/logo_large.png" />
                                    <h2 className="text-white mt-4 mb-0 d-none d-lg-block">The wait is over...</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 loginRight">
                            <form className="justify-content-around" onSubmit={this.submitForm}>
                                <div className="fhead">
                                    <h2 className="text-white flexx-fill">Forgot Password</h2>
                                </div>
                                <div className="fbody auth-form-light text-left mt-2 mt-lg-5 text-white">
                                    <div className="form-group over-label password-field mb-5">
                                        <div className="bottom-line">
                                            <input
                                                className={email.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                type="email"
                                                name={email.name}
                                                value={email.value}
                                                onChange={this.onChange}
                                            />
                                            <label className="text-white">Email</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="ffoot">
                                    <button type="submit" className="btn btn-block btn-primary btn-lg font-weight-bold auth-form-btn mt-3 mt-lg-5">Confirm</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    private submitForm = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { email } = this.state;
            const model = {
                email: email.value
            };
            this.setState({ showLoader: true });
            forgotPassword(model).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 200) {
                    toast.success(res.message);
                    setTimeout(() => {
                        window.location.href = `${CONSTANT.url.forgotPasswordVerify}?i=${res.data.uid}`;
                    }, 1000)
                }
                if (res.status === 400) {
                    toast.error(res.message);
                }
                if (res.status === 500) {
                    toast.error(res.message);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        }
    }
}

export default ForgotPassword;