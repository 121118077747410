import * as axios from 'axios';
import { getAuthHeader } from './../utils';

export const getAllStats = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/statics`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        throw error.response;
    });
}

export const getRatingGraphStats = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/avg_rating`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        throw error.response;
    });
}

export const getViewGraphStats = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/avg_view`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        throw error.response;
    });
}

export const getDownloadsGraphStats = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/avg_download`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        throw error.response;
    });
}