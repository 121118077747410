import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import { postUpdates, getUpdates, getCatalogueDetail } from '../../action/CatalogueAction';
import { onChange, validateForm } from './../../utils';
import CONSTANT from './../../constant';

class CatalogueUpdates extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: props.match.params.id,
            showLoader: false,
            list: undefined,
            updates: { name: 'updates', value: '', error: '', isRequired: true },
            detail: undefined

        }
    }

    componentDidMount() {
        getCatalogueDetail(this.state.id).then((res: any) => {
            if (res.status === 200) {
                this.setState({
                    showLoader: false,
                    detail: res.data,
                });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
        this.loadList();
    }

    public render() {
        const { showLoader, updates, list, id, detail } = this.state;
        const url = CONSTANT.url.catalogueEdit.replace(':id', id);
        return (
            <React.Fragment>
                <div className="container-scroller">
                    <Header title='My Catalogues' />
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar />
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href={CONSTANT.url.myCatalogues}>My Catalogues</a></li>
                                        {detail && detail.title &&
                                            <li className="breadcrumb-item"><a href={url}>{detail && detail.title}</a></li>
                                        }
                                        <li className="breadcrumb-item active" aria-current="page">Updates</li>
                                    </ol>
                                </nav>
                                <h6>Sale Notifications</h6>
                                <form onSubmit={this.onSubmit}>
                                    <div className="page-header write">
                                        <div className="flex-fill">
                                            <input
                                                type="text"
                                                className={updates.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Write an update here eg. Sale starts tomorrow, Supplementary Sheet available now..."
                                                name={updates.name}
                                                value={updates.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="add">
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </form>
                                <table className="table no-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th><span className="h6">Date</span></th>
                                            <th><span className="h6">Message</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list && list.map((item: any) => {
                                            return (
                                                <tr>
                                                    <td style={{ width: '200px' }}>{moment(item.createdOn).format("DD/MM/YYYY")}</td>
                                                    <td>{item.update}</td>
                                                </tr>
                                            )
                                        })}
                                        {list !== undefined && list.length === 0 &&
                                            <tr className="border-bottom border-200">
                                                <td colSpan={15} className="align-middle text-center">No record found</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div id="mask"></div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;

        onChange(this, name, value);
    }

    loadList = () => {
        const { id } = this.state;
        this.setState({ showLoader: true });
        getUpdates(id).then((res: any) => {
            if (res.status === 200) {
                this.setState({
                    showLoader: false,
                    list: res.data,
                });
            }
        }).catch((error: any) => {
            this.setState({
                showLoader: false,
                list: [],
            });
        });
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { id, updates } = this.state;

            this.setState({ showLoader: true });
            const model = {
                catalogueId: id,
                update: updates.value
            }
            postUpdates(model).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 201) {
                    toast.success("New Updates added!");
                    this.loadList();
                    this.setState({ updates })
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        }
    }

}

export default CatalogueUpdates;