import * as axios from 'axios';
import { getAuthHeader } from './../utils';

export const getAllSubPlans = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/plan/vendor-all`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const getSubscriptionDeail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/stripe/vendor-subscription-detail`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}