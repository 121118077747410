export default {
    url: {
        login: '/',
        register: '/register',
        registerVerification: '/register/verification',
        forgotPassword: '/forgot-password',
        forgotPasswordVerify: '/forgot-password-verify',
        changePassword: '/change-password/:id',
        resetPassword: '/reset-password',
        logout: '/logout',

        dashboard: '/dashboard',

        myCatalogues: '/catalogues',
        addCatalogue: '/catalogue/add',
        catalogueEdit: '/catalogue/edit/:id',
        catalogueUpdates: '/catalogue/updates/:id',

        buyProduct: '/buy-product',
        payments: '/payments',

        subscriptions: '/subscriptions',
        checkout: '/subscriptions/checkout',
        coupon: '/subscriptions/coupon',
        subscriptionSuccess: '/subscriptions/success',
        subscriptionCancel: '/subscriptions/cancel',

        myProfile: '/my-profile',
        editProfile: '/my-profile/:id',
        setting: '/setting'
    },
    keys: {
        token: 'tk.sv.studbook',
        userDetail: 'ud.sv.studbook',
        remember: 'ld.sv.studbook',
    },
    fileDirectory: {
        catalogues: 'catalogues',
        catalogueThumbnail: 'catalogue-thumbnail',
        category: 'category',
        profilePicture: 'profile_picture',
        profileCover: 'profile-cover'
    }
}