import * as React from 'react';
import queryString from 'query-string';

import Loader from '../../component/common/Loader';
import { getVendorDetail } from '../../action/VendorAction';
import { getSubscriptionDeail } from './../../action/SubscriptionAction';
import { getStorage } from '../../utils';
import CONSTANT from '../../constant';

const defaultProfile = require('./../../assets/images/default.jpg')

class PayemntFailed extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        const parsed: any = queryString.parse(props.location.search);
        this.state = {
            sessionId: parsed.session_id,
            showLoader: false,
            uid: '',
            name: '',
            photoURL: ''
        }
    }

    componentDidMount() {
        const ud = getStorage(CONSTANT.keys.userDetail);
        this.setState({ uid: ud.uid, name: ud.displayName, photoURL: ud.photoURL });
        this.loadList();
    }

    public render() {
        const { showLoader, planId, name, photoURL, list } = this.state;
        return (
            <React.Fragment>
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        <div className="main-panel">
                            <div className="content-wrapper bg-white">
                                <div className="profile-header pt-3">
                                    <div className="header-bg -lg" style={{ "backgroundImage": "url(/assets/images/profile-header.png)" }}></div>
                                </div>
                                <div className="position-relative z-index-11">
                                    <div className="col-sm-8 m-auto d-flex align-items-center justify-content-between">
                                        <a className="navbar-brand brand-logo mx-0 text-center" href="#">
                                            <img src="/assets/images/logo-small.png" alt="logo" />
                                            <h5 className="text-white mt-2">The wait is over...</h5>
                                        </a>
                                        <ul className="navbar-nav navbar-nav-right">
                                            <li className="nav-item nav-profile dropdown">
                                                <a href="" className="nav-link bg-transparent d-flex align-items-center" id="profileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <div className="nav-profile-img mr-3">
                                                        {photoURL ?
                                                            <img src={photoURL} alt="image" />
                                                            : <img src={defaultProfile} alt="image" />}
                                                    </div>
                                                    <div className="nav-profile-text">
                                                        <p className="mb-1">{name}</p>
                                                    </div>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="profileDropdown">
                                                    <a href={CONSTANT.url.logout} className="dropdown-item">Logout</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-8 ml-auto mr-auto mt-4 pt-2">
                                        <div className="card no-shadow">
                                            <div className="card-body px-3 py-3 px-lg-5 py-lg-4 mt-lg-3">
                                                <h4>Failed</h4>
                                                <div className="card right-lg-pattern lg-shadow mb-4 mt-2">
                                                    <div className="card-body d-lg-flex align-items-center justify-content-between px-2 px-lg-4 py-3 py-lg-4">
                                                        <div className="left col flex-fill">
                                                            <h4 className="font-weight-600 mb-2"></h4>
                                                            <div className="clearfix d-flex align-items-center h6 font-weight-300 mb-2 mb-lg-3">
                                                                <i className="mdi mdi-checkbox-blank-circle text-primary x-small mr-1"></i>
                                                            </div>
                                                            <p className="h5 font-weight-600 mb-1"></p>
                                                            <p className="my-0 text-gray font-weight-400"></p>
                                                        </div>
                                                        <div className="right text-lg-center d-flex d-lg-block align-items-center justify-content-between col mt-2 mt-lg-0">
                                                            <h1 className="my-0 font-xs-20 font-sm-26"></h1>
                                                            <a onClick={this.onFailed} className="btn btn-primary btn-md h3 min-width no-radius mb-0 mb-lg-2 mt-lg-3">Continue</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="mask"></div>
                            <footer className="footer">
                                <div className="text-center text-muted">
                                    Copyright &copy; 2020 <span className="text-default">Studbooks</span>. All rights reserved.
					            </div>
                            </footer>
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment>
        )
    }

    loadList = () => {
        const { sessionId } = this.state;
        this.setState({ showLoader: true });
        getSubscriptionDeail({ sessionId }).then((res: any) => {
            this.setState({ showLoader: false });
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    onFailed = () => {
        const { uid } = this.state;
        this.setState({ showLoader: true });
        getVendorDetail({ uid }).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                if (res.data.isVendorPlanSubscribed) {
                    window.location.href = CONSTANT.url.dashboard;
                } else {
                    if (res.data.signupSteps == 1) {
                        this.props.history.push(CONSTANT.url.registerVerification.replace(':id', res.data.uid));
                    } else if (res.data.signupSteps == 2) {
                        this.props.history.push(CONSTANT.url.buyProduct);
                    } else if (res.data.signupSteps == 3) {
                        this.props.history.push(CONSTANT.url.buyProduct);
                    }
                }
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false, vendorPlanList: [], totalVendorPlan: 0, });
        });
    }
}

export default PayemntFailed;