import React from 'react';
import { removeAllCookies } from '../../utils';
import CONSTANT from '../../constant';

class Logout extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        removeAllCookies();
        localStorage.removeItem('rsc_cache');
        localStorage.removeItem(CONSTANT.keys.userDetail);
        localStorage.removeItem(CONSTANT.keys.token);
        localStorage.removeItem(CONSTANT.keys.remember);
        props.history.push('/');
    }

    public render() {
        return (
            <React.Fragment>
                <div className="text-center">Redirecting to home page</div>
            </React.Fragment>
        );
    }
}

export default Logout;