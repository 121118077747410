import * as React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Header from './../../component/common/Header';
import Sidebar from './../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import CONSTANT from './../../constant';
import { getCatalogueList } from './../../action/CatalogueAction';
import { onChange } from './../../utils';

class MyCatalogues extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            page: 1,
            limit: 10,
            sort: 'desc',
            count: 0,
            list: undefined,
            filterList: undefined,
            showLoader: false,
            notification:[],
            keyword: { name: 'keyword', value: '', error: '', isRequired: false },
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { filterList, keyword, notification } = this.state;
        return (
            <React.Fragment>
                <div className="container-scroller">
                    {
                        notification.length > 0 ? <Header title='My Catalogues' notifications={notification} />
                            : <Header title='My Catalogues' />
                    }
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar />
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="page-header">
                                    <div className="flex-fill mr-2 pr-1">
                                        <input type="text" className="search form-control" placeholder="Search Catalogue" name={keyword.name} value={keyword.value} onChange={this.searchVendor} />
                                    </div>
                                    <div className="add">
                                        <Link className="btn btn-primary" to={CONSTANT.url.addCatalogue}>New Catalogue <img src="/assets/images/icons/add.png" /></Link>

                                    </div>
                                </div>
                                <div className="table-responsive">
                                <table className="table no-bordered table-striped" id="dataTable">
                                    <thead>
                                        <tr>
                                            <th><span className="h6">Catalogue</span></th>
                                            <th><span className="h6">Views</span></th>
                                            <th><span className="h6">Ratings</span></th>
                                            <th><span className="h6">Reviews</span></th>
                                            <th><span className="h6">Updates</span></th>
                                            <th><span className="h6">Period</span></th>
                                            <th><span className="h6">Status</span></th>
                                            <th><span className="h6">Edit</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            filterList !== undefined && filterList.map((item: any, index: any) => {
                                                const rating = item.rating ? parseInt(item.rating) : 1;
                                                const isDecimal = item.rating ? item.rating % 1 : 0;
                                                const url = CONSTANT.url.catalogueEdit.replace(':id', item.id);
                                                return (
                                                    <React.Fragment key={index}>
                                                        <tr>
                                                            <td>{item.title}</td>
                                                            <td>{item.views ? item.views : '-'}</td>
                                                            <td>
                                                                {this.renderRating(rating)}
                                                                {isDecimal ?
                                                                    <i className="mdi mdi-star-half text-warning h4"></i>
                                                                    : null}
                                                            </td>
                                                            <td>{item.reviews ? item.reviews : '-'}</td>
                                                            <td>{item.updates ? item.updates : '-'}</td>
                                                            <td>{`${moment(item.rangeFromDate).format("MMM DD, YYYY")} - ${moment(item.rangeToDate).format("MMM DD, YYYY")}`}</td>
                                                            <td>{item.status ? item.status : '-'}</td>
                                                            <td>
                                                                <div className="action text-center d-flex align-items-center justify-content-center">
                                                                    <Link className="text-primary mx-2" to={url}>
                                                                        <i className="mdi mdi-lead-pencil" title="Edit"></i>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            })
                                        }

                                        {filterList !== undefined && filterList.length === 0 &&
                                            <tr className="border-bottom border-200">
                                                <td colSpan={15} className="align-middle text-center">No record found</td>
                                            </tr>
                                        }

                                    </tbody>
                                </table>
                                </div>
                            </div>
                            <div id="mask"></div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    searchVendor = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;

        onChange(this, name, value);

        const filterSearch = this.filterIt(this.state.list, value);
        
        const filter = filterSearch.filter((item: any) => {
            let rangeToDate = new Date(item.rangeToDate);
            let saleToDate = new Date(item.saleToDate);
            return saleToDate > rangeToDate ? null : item;
        });
        if (value) {
            this.setState({ filterList: filter });
        } else {
            this.setState({ filterList: filterSearch });
        }
    }

    filterIt = (arr: any, searchKey: string) => {
        return arr.filter((obj: any) => {
            return Object.keys(obj).some((key) => {
                if (obj[key] !== null) {
                    const tempKey = obj[key].toString().toLowerCase();
                    const tempSearch = searchKey.toLowerCase();
                    return tempKey.includes(tempSearch);
                }
            });
        });
    }


    renderRating = (item: number) => {
        return (
            <React.Fragment>
                {Array.from(Array(item), (e, i) => {
                    return <i className="mdi mdi-star text-warning h4"></i>
                })}
            </React.Fragment>
        )
    }

    loadList = () => {
        const { page, limit, sort, notification } = this.state;
        getCatalogueList(page, limit, sort).then((res: any) => {
            if (res.status === 200) {                
                res.data.list.map((item: any) => {
                    if (item.isApproved) {
                        let createdOn = item.createdOn;
                        let string = createdOn.replace(/[^a-zA-Z ]/gm, "");
                        let s = string.split("");
                        let dateTime = createdOn.replace(s[0], " ");
                        dateTime = dateTime.split(" ");
                        let date = dateTime[0];
                        let time = dateTime[1].replace(s[1], " ");
                        time = time.split(":");
                        time = `${time[0]}:${time[1]}:${Math.trunc(time[2])}`
                        notification.push({ read: item.title, date: moment(date).format("MMM DD, YYYY"), time: time})
                    }
                })
                this.setState({ showLoader: false, list: res.data.list, filterList: res.data.list, count: res.data.count });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false, list: [], count: 0 });
        });
    }
}

export default MyCatalogues;
