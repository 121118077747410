import * as axios from 'axios';
import { getAuthHeader } from './../utils';

export const getSession = (sessionId: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/stripe/session?id=${sessionId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const createSubscription = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/stripe/vendor-subscription`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const createFreeSubscription = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/stripe/vendor-free-subscription`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const confirmSubscription = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/stripe/vendor-confirm-subscription`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const getVendorProductHistory = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/product`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        throw error.response;
    });
}