import * as axios from 'axios';
import { getAuthHeader } from './../utils';

export const addActivity = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/activity`;
    return axios.post(url, model).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        throw error.response;
    });
}

export const getActivityLog = (userId: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/activity/${userId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        throw error.response;
    });
}