import * as React from 'react';
import NumberFormat from 'react-number-format';
import firebase from "firebase/app";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

import Header from './../../component/common/Header';
import Sidebar from './../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Loader from './../../component/common/Loader';
import { getVendorDetail, updateVendorDetail, deleteUser } from './../../action/VendorAction';
import CONSTANT from '../../constant';
import { onChange, validateForm, getStorage, setStorage } from './../../utils';
const defaultProfile = require('./../../assets/images/default.jpg');
const delIcon = require('./../../assets/images/trash.png')

class EditProfile extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            uid: props.match.params.id,
            showLoader: false,
            detail: undefined,
            userDetail: undefined,
            firstName: { name: 'firstName', value: '', error: '', isRequired: true },
            lastName: { name: 'lastName', value: '', error: '', isRequired: true },
            studName: { name: 'studName', value: '', error: '', isRequired: true },
            tradingName: { name: 'tradingName', value: '', error: '', isRequired: true },
            email: { name: 'email', value: '', error: '', isRequired: true },
            countryCode: { name: 'countryCode', value: '', error: '', isRequired: false },
            phone: { name: 'phone', value: '', error: '', isRequired: true },
            address: { name: 'address', value: '', error: '', isRequired: true },
            website: { name: 'website', value: '', error: '', isRequired: true },
            profileURL: undefined,
            coverURL: undefined,
            profileFileName: undefined,
        }
    }

    componentDidMount() {
        const ud = getStorage(CONSTANT.keys.userDetail);
        this.setState({ userDetail: ud }, () => {
            this.loadList();
        });
    }

    public render() {
        const { detail, firstName, lastName, studName, tradingName, email, phone, address, website, showLoader } = this.state;
        return (
            <React.Fragment>
                <div className="container-scroller">
                    <Header title='My Profile' />
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar />
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="profile-header px-3 mb-5 mb-lg-0">
                                    <div className="header-bg" style={{ backgroundImage: `${detail && detail.profileCoverURL ? `url(${detail.profileCoverURL})` : "url('./../../assets/images/profile-header.png')"}` }}>
                                        <div className="edit"><img src="/assets/images/icons/camera.png" /><input type="file" accept="image/*" onChange={this.onProfileCoverAdd} /></div>
                                    </div>
                                    <div className="profile-pic">
                                        <div className="tumbnail">
                                            {detail &&
                                                <img src={detail.photoURL ? detail.photoURL : defaultProfile} height="100" width="100" alt="avatar" />
                                            }
                                            {detail && detail.photoURL &&
                                                <a href="#" onClick={this.deleteProfile}><div className="del"><img height="25" width="25" src={delIcon} /></div></a>
                                            }
                                            <div className="edit"><img src="./../../assets/images/icons/camera.png" /><input type="file" accept="image/*" onChange={this.onProfileAdd} /></div>
                                        </div>
                                    </div>
                                </div>
                                <form className="row px-3 mb-5 pt-4 pt-lg-0" onSubmit={this.submitForm}>
                                    <div className="form-group col-sm-4 mt-0 mt-lg-3">
                                        <label>First Name</label>
                                        <input
                                            className={firstName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            type="text"
                                            name={firstName.name}
                                            value={firstName.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group col-sm-4 mt-0 mt-lg-3">
                                        <label>Last Name</label>
                                        <input
                                            className={lastName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            type="text"
                                            name={lastName.name}
                                            value={lastName.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group col-sm-4 mt-0 mt-lg-3">
                                        <label>Trading</label>
                                        <input
                                            className={tradingName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            type="text"
                                            name={tradingName.name}
                                            value={tradingName.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group col-sm-4 mt-0 mt-lg-3">
                                        <label>Stud Name</label>
                                        <input
                                            className={studName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            type="text"
                                            name={studName.name}
                                            value={studName.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group col-sm-4 mt-0 mt-lg-3">
                                        <label>Website</label>
                                        <input
                                            className={website.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            type="text"
                                            name={website.name}
                                            value={website.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group col-sm-4 mt-0 mt-lg-3">
                                        <label>Email</label>
                                        <input
                                            className={email.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            type="email"
                                            name={email.name}
                                            value={email.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group col-sm-4 mt-0 mt-lg-3">
                                        <label>Phone No</label>
                                        <NumberFormat
                                            className={phone.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            name={phone.name}
                                            maxLength={10}
                                            allowNegative={false}
                                            value={phone.value}
                                            allowLeadingZeros={true}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group col-sm-8 mt-0 mt-lg-3">
                                        <label>Address</label>
                                        <input
                                            className={address.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            type="text"
                                            name={address.name}
                                            value={address.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="col-sm-12 text-right mt-2 d-flex align-items-center justify-content-center justify-content-lg-end">
                                        <button type="button" className="btn btn-white px-4 min-width mr-2" data-toggle="modal" data-target="#deleteAccount" onClick={this.deleteVendor}><b>Delete Account</b></button>
                                        <button type="submit" className="btn btn-primary px-4 min-width"><b>Save</b></button>
                                    </div>
                                </form>
                            </div>
                            <div id="mask"></div>
                            <Footer />
                        </div>

                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    loadList = () => {
        const { uid, firstName, lastName, studName, tradingName, email, phone, address, website, userDetail } = this.state;
        this.setState({ showLoader: true });
        getVendorDetail({ uid }).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                const udObj = {
                    ...userDetail,
                    photoURL: res.data.photoURL
                }
                setStorage(CONSTANT.keys.userDetail, udObj);
                this.setState({ detail: res.data, profileFileName: res.data.profileFileName });
                onChange(this, firstName.name, res.data.firstName);
                onChange(this, lastName.name, res.data.lastName);
                onChange(this, tradingName.name, res.data.tradingName);
                onChange(this, studName.name, res.data.studName);
                onChange(this, website.name, res.data.website);
                onChange(this, email.name, res.data.email);
                onChange(this, phone.name, res.data.phone);
                onChange(this, address.name, res.data.address);
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    onProfileAdd = (e: any) => {
        const f = e.target.files;
        var file = f[0];
        if (file) {
            this.handleUploadProfile(file);
        }
        e.target.value = '';
    }

    handleUploadProfile = (file: any) => {
        this.setState({ showLoader: true });

        const extArray = file.name.split('.');
        const ext = extArray[extArray.length - 1];
        const originalFilename = extArray[0];
        let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
        const fileDirectory = `${CONSTANT.fileDirectory.profilePicture}/${fileName}`;

        const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

        uploadTask.on("state_changed", (snapshot: any) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
        },
            (error: any) => {
                console.log(error);
            },
            () => {
                firebase.app().storage()
                    .ref(CONSTANT.fileDirectory.profilePicture)
                    .child(fileName)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ profileURL: url, profileFileName: fileName }, () => {
                            this.updatePrrofileImage();
                        });
                    });
            }
        );
    };

    onProfileCoverAdd = (e: any) => {
        const f = e.target.files;
        var file = f[0];
        if (file) {
            this.handleUploadProfileCover(file);
        }
        e.target.value = '';
    }

    handleUploadProfileCover = (file: any) => {
        this.setState({ showLoader: true });

        const extArray = file.name.split('.');
        const ext = extArray[extArray.length - 1];
        const originalFilename = extArray[0];
        let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
        const fileDirectory = `${CONSTANT.fileDirectory.profileCover}/${fileName}`;

        const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

        uploadTask.on("state_changed", (snapshot: any) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
        },
            (error: any) => {
                console.log(error);
            },
            () => {
                firebase.app().storage()
                    .ref(CONSTANT.fileDirectory.profileCover)
                    .child(fileName)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ coverURL: url }, () => {
                            this.updateProfileCoverImage();
                        });
                    });
            }
        );
    };

    updatePrrofileImage = () => {
        const { uid, profileURL, profileFileName, userDetail } = this.state;
        const model = {
            uid: uid,
            photoURL: profileURL,
            profileFileName: profileFileName
        };
        updateVendorDetail(model).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                toast.success("Profile updated.");
                this.loadList();
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    updateProfileCoverImage = () => {
        const { uid, coverURL } = this.state;
        const model = {
            uid: uid,
            profileCoverURL: coverURL
        };
        updateVendorDetail(model).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                toast.success("Profile updated.");
                this.loadList();
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    private submitForm = async (e: any) => {
        const { uid, firstName, lastName, studName, tradingName, email, phone, address, website } = this.state;
        e.preventDefault();
        if (validateForm(this)) {
            const st = this.state;
            const model = {
                uid: uid,
                firstName: firstName.value,
                lastName: lastName.value,
                studName: studName.value,
                tradingName: tradingName.value,
                email: email.value,
                phone: phone.value,
                address: address.value,
                website: website.value
            };
            updateVendorDetail(model).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 200) {
                    toast.success("Profile updated.");
                    this.props.history.push(CONSTANT.url.myProfile);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        }
    }

    deleteVendor = (item: any) => {
        const { uid } = this.state;
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete your account?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                deleteUser(uid, { isActive: false }).then((res: any) => {
                    this.setState({ showLoader: false });
                    Swal.fire(
                        'Deleted!',
                        'Your account has been deleted.',
                        'success'
                    )
                    this.props.history.push(CONSTANT.url.logout);
                    this.props.history.index = 0;
                }).catch((error: any) => {
                    this.setState({ showLoader: false });
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your are safe :)',
                    'error'
                )
            }
        })
    }

    deleteProfile = (e: any) => {
        e.preventDefault();
        this.setState({ showLoader: true });
        firebase.app().storage()
            .ref(CONSTANT.fileDirectory.profilePicture)
            .child(this.state.profileFileName)
            .delete()
            .then(url => {
                this.setState({ profileFileName: "", showLoader: false, photoURL: "", profileURL: "" }, () => {
                    this.updatePrrofileImage();
                });
            });
    }

}

export default EditProfile;