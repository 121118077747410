import * as React from 'react';
import moment from 'moment';

import Header from './../../component/common/Header';
import Sidebar from './../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Loader from './../../component/common/Loader';
import { getVendorProductHistory } from './../../action/PurchaseAction';

class Payments extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
            list: undefined,
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader, list } = this.state;
        return (
            <React.Fragment>
                <div className="container-scroller">
                    <Header title='Payments' />
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar />
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <h6>Products</h6>
                                <div className="table-responsive">
                                    <table className="table no-bordered table-striped mt-3 mb-5">
                                        <thead>
                                            <tr>
                                                <th><span className="h6">Date</span></th>
                                                <th><span className="h6">Order Details</span></th>
                                                <th><span className="h6">Status</span></th>
                                                <th><span className="h6">Amount</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list && list.map((subscribedPlanItem: any, subscribedPlanIndex: any) => {
                                                let currentDate = moment();
                                                let prevDate = moment.unix(subscribedPlanItem.endDate);
                                                const diff = prevDate.diff(currentDate, 'days')
                                                return (
                                                    <React.Fragment>
                                                        <tr>
                                                            <td style={{ "width": '240px' }}>{moment(subscribedPlanItem.createdOn).format("DD/MM/YYYY")}</td>
                                                            <td>{`Buy subscription plan (${subscribedPlanItem.name})`}</td>
                                                            <td style={{ "width": '200px' }}>Successful</td>
                                                            <td>{`$${subscribedPlanItem.price}`}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            })}

                                            {
                                                list !== undefined && list.length === 0 && <tr>
                                                    <td colSpan={10} className="text-center">No records found</td>
                                                </tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div id="mask"></div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment>
        )
    }

    loadList = () => {
        this.setState({ showLoader: true });
        getVendorProductHistory().then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                this.setState({
                    list: res.data,
                });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false, vendorPlanList: [], totalVendorPlan: 0, });
        });
    }
}

export default Payments;