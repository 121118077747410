import * as axios from 'axios';
import { getAuthHeader } from './../utils';

export const getVendorPlan = (page: number, limit: number, sort: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/plan/vendor?p=${page}&l=${limit}&s=${sort}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const getVendorPlanDetail = (planId: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/plan/vendor/detail/${planId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const getVendorCoupon = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/coupon/vendor`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const getCouponDetail = (couponId: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/coupon/vendor/${couponId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}


export const applyCoupon = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/coupon/apply`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const checkoutSession = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/stripe/checkout`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}