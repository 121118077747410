import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';
import $ from 'jquery';

import Loader from './../../component/common/Loader';
import { onChange, validateForm } from './../../utils';
import CONSTANT from './../../constant';
import { forgotPassword, validateForgotOTP } from './../../action/AuthAction';
import { getVendorInfo } from './../../action/VendorAction';

class ForgotPassword extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const parsed: any = queryString.parse(props.location.search);
        this.state = {
            uid: parsed.i,
            inputOne: { name: 'inputOne', value: '', error: '', isRequired: true },
            inputTwo: { name: 'inputTwo', value: '', error: '', isRequired: true },
            inputThree: { name: 'inputThree', value: '', error: '', isRequired: true },
            inputFour: { name: 'inputFour', value: '', error: '', isRequired: true },
            showLoader: false
        };
    }

    componentDidMount() {
        this.loadKeyFocus();
        this.loadList();
    }

    public render() {
        const { inputOne, inputTwo, inputThree, inputFour, showLoader } = this.state;
        return (
            <React.Fragment>
                <div className="container-fluid page-body-wrapper full-page-wrapper login-bg">
                    <div className="row flex-grow justify-content-center">
                        <div className="col-sm-6 loginLeft align-items-center justify-content-center p-0 d-flex">
                            <div className="logo-strip flex-fill">
                                <div className="brand-logo">
                                    <img src="/assets/images/logo_large.png" />
                                    <h2 className="text-white mt-4 mb-0 d-none d-lg-block">The wait is over...</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 loginRight">
                            <form className="justify-content-around">
                                <div className="fhead justify-content-between">
                                    <h2 className="text-white flex-fill">Forgot Password?</h2>
                                    <h4 className="text-muted mt-2">Enter the OTP we have just sent to your registered email.</h4>
                                </div>
                                <div className="fbody auth-form-light text-left">
                                    <div className="form-group d-flex otp otpInput">
                                        <input
                                            className={inputOne.error.length > 0 ? "form-control border-white is-invalid" : "form-control border-white"}
                                            type="text"
                                            name={inputOne.name}
                                            value={inputOne.value}
                                            onChange={this.onChange}
                                            maxLength={1}
                                        />
                                        <input
                                            className={inputTwo.error.length > 0 ? "form-control border-white is-invalid" : "form-control border-white"}
                                            type="text"
                                            name={inputTwo.name}
                                            value={inputTwo.value}
                                            onChange={this.onChange}
                                            maxLength={1}
                                        />
                                        <input
                                            className={inputThree.error.length > 0 ? "form-control border-white is-invalid" : "form-control border-white"}
                                            type="text"
                                            name={inputThree.name}
                                            value={inputThree.value}
                                            onChange={this.onChange}
                                            maxLength={1}
                                        />
                                        <input
                                            className={inputFour.error.length > 0 ? "form-control border-white is-invalid" : "form-control border-white"}
                                            type="text"
                                            name={inputFour.name}
                                            value={inputFour.value}
                                            onChange={this.onChange}
                                            maxLength={1}
                                        />
                                    </div>
                                    <div className="d-flex clearfix mt-5"></div>
                                    <div className="mt-3 text-center text-lg-left">
                                        <button type="button" className="auth-link font-weight-600 text-primary h4" onClick={this.onResendOTP}>Resend OTP</button>
                                    </div>
                                </div>
                                <div className="ffoot">
                                    <button type="button" className="btn btn-block btn-primary btn-lg font-weight-bold auth-form-btn" onClick={this.onConfirm}>Confirm</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    loadKeyFocus = () => {
        const self = this;
        $('.otpInput input').keypress(function () {
            $(self).next().focus();
        }).keyup(function (e: any) {
            if (e.keyCode == 8) {
                $(self).prev().focus();
            }
        });
    }

    loadList = () => {
        this.setState({ showLoader: true });
        const { uid } = this.state;
        getVendorInfo(uid).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                this.setState({ email: res.data.email });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false, list: [], count: 0 });
        });
    }

    private onResendOTP = async (e: any) => {

        const { email } = this.state;
        const model = {
            email
        };
        this.setState({ showLoader: true });
        forgotPassword(model).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                toast.success(res.message);
            }
            if (res.status === 400) {
                toast.error(res.message);
            }
            if (res.status === 500) {
                toast.error(res.message);
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    private onConfirm = async (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { uid, inputOne, inputTwo, inputThree, inputFour, showLoader } = this.state;
            const model = {
                uid: uid,
                validateType: 'forgot',
                otp: `${inputOne.value}${inputTwo.value}${inputThree.value}${inputFour.value}`
            };
            this.setState({ showLoader: true });
            validateForgotOTP(model).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 200) {
                    toast.success(res.message);
                    setTimeout(() => {
                        window.location.href = `${CONSTANT.url.resetPassword}?t=${model.otp}&i=${res.data.uid}`;
                    }, 1000)
                }
                if (res.status === 400) {
                    toast.error(res.message);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        }
    }

    private submitForm = async (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const st = this.state;
            const model = {
                email: st.email.value,
                password: st.password.value
            };
            this.setState({ showLoader: true });

        }
    }
}

export default ForgotPassword;