import * as axios from 'axios';
import { getAuthHeader } from './../utils';

export const createCatalogue = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/newCatalogue`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const getCatalogueList = (page: number, limit: number, sort: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/getVendorCatalogue?p=${page}&l=${limit}&s=${sort}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const getCatalogueDetail = (docId: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/getCatalogueDetail/${docId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const updateCatalogueDetail = (model: any, docId: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/updateCatalogue/${docId}`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const approveCatalogue = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/approveCatalogue`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const getCategoryList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/category`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const getStudnameList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/getStudNameList`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const getAnimalTypeList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/getAnimalTypeList`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const getBreeds = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/getBreedList`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const postUpdates = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/updates`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const getUpdates = (catalogueId: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/catelogue/updates/${catalogueId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const getAnimalType = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/getAnimalTypeList`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const getBreedList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/getBreedList`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const getStudNameList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/getStudNameList`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

// Animal Mapping with Breed
export const getAllAnimalTypeWithBreed = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/getAnimalTypeWithBreed`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const getBreedWithAnimal = (animalTypeId: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/getBreedViaAnimalType/${animalTypeId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const addStudName = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/addStudName`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const updateAnimalStud = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/animal/stud`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}