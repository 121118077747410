import * as axios from 'axios';
import { getAuthHeader } from './../utils';

export const getVendorDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/vendor/detail`;
    return axios.post(url, model, /* getAuthHeader()*/).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        throw error.response;
    });
}

export const getVendorInfo = (uid: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/vendor/info/${uid}`;
    return axios.get(url).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        throw error.response;
    });
}

export const updateVendorDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/vendor/detail`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        throw error.response;
    });
}

export const deleteUser = (userId: any, model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/user/delete/${userId}`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((err: any) => {
        throw err.response;
    });
}

export const changePassword = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/auth/change-password`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        throw error.response;
    });
}