import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import CONSTANT from './constant';
import { getStorage } from './utils';

// auth routes
import Login from './container/auth/Login';
import Register from './container/auth/Register';
import RegisterVerification from './container/auth/RegisterVerification';
import ForgotPassword from './container/auth/ForgotPassword';
import ForgotPasswordVerify from './container/auth/ForgotOTPVerify ';
import ResetPassword from './container/auth/ResetPassword';
import ChangePassword from './container/auth/ChangePassword';
import Logout from './container/auth/Logout';
// auth routes

import Dashboard from './container/Dashboard';

import MyCatalogues from './container/catalogue/List';
import AddNewCatalogue from './container/catalogue/Add';
import EditCatalogue from './container/catalogue/Edit';
import CatalogueUpdates from './container/catalogue/Updates';

import BuyProduct from './container/product/Buy';
import Payments from './container/payments/Payments';
import Checkout from './container/product/Checkout';
import Coupon from './container/product/Coupon';
import subscriptionSuccess from './container/product/Success';
import subscriptionCancel from './container/product/Cancel';

import Subscriptions from './container/subscriptions/Subscriptions';

import MyProfile from './container/my-profile/Profile';
import EditProfile from './container/my-profile/EditProfile';

// misc route
import FourZeroFour from './container/error/FourZeroFour';
// misc route

const PrivateRoute = (props: any) => {
    const token = getStorage(CONSTANT.keys.token);
    if (token) {
        return (
            <React.Fragment>
                <Route exact={true} path={props.path} component={props.component} />
            </React.Fragment>
        )
    } else {
        return (
            <Login />
        )
    }
}

const Router = (props: any) => {
    return (
        <Switch>
            {/* auth routes */}
            <Route exact={true} path={CONSTANT.url.login} component={Login} />
            <Route exact={true} path={CONSTANT.url.register} component={Register} />
            <Route exact={true} path={CONSTANT.url.registerVerification} component={RegisterVerification} />
            <Route exact={true} path={CONSTANT.url.forgotPassword} component={ForgotPassword} />
            <Route exact={true} path={CONSTANT.url.forgotPasswordVerify} component={ForgotPasswordVerify} />
            <Route exact={true} path={CONSTANT.url.resetPassword} component={ResetPassword} />
            <Route exact={true} path={CONSTANT.url.logout} component={Logout} />
            
            <Route exact={true} path={CONSTANT.url.changePassword} component={ChangePassword} />
            {/* auth routes */}

            <PrivateRoute exact={true} path={CONSTANT.url.dashboard} component={Dashboard} />

            {/* catalogue routes */}
            <PrivateRoute exact={true} path={CONSTANT.url.myCatalogues} component={MyCatalogues} />
            <PrivateRoute exact={true} path={CONSTANT.url.addCatalogue} component={AddNewCatalogue} />
            <PrivateRoute exact={true} path={CONSTANT.url.catalogueEdit} component={EditCatalogue} />
            <PrivateRoute exact={true} path={CONSTANT.url.catalogueUpdates} component={CatalogueUpdates} />
            {/* catalogue routes */}

            {/* payments routes */}
            <PrivateRoute exact={true} path={CONSTANT.url.buyProduct} component={BuyProduct} />
            <PrivateRoute exact={true} path={CONSTANT.url.payments} component={Payments} />
            <PrivateRoute exact={true} path={CONSTANT.url.checkout} component={Checkout} />
            <PrivateRoute exact={true} path={CONSTANT.url.coupon} component={Coupon} />
            <PrivateRoute exact={true} path={CONSTANT.url.subscriptionSuccess} component={subscriptionSuccess} />
            <PrivateRoute exact={true} path={CONSTANT.url.subscriptionCancel} component={subscriptionCancel} />
            {/* payments routes */}

            {/* subscriptions routes */}
            <PrivateRoute exact={true} path={CONSTANT.url.subscriptions} component={Subscriptions} />
            {/* subscriptions routes */}

            {/* my profile routes */}
            <PrivateRoute exact={true} path={CONSTANT.url.myProfile} component={MyProfile} />
            <PrivateRoute exact={true} path={CONSTANT.url.editProfile} component={EditProfile} />
            {/* my profile routes */}

            <Route component={FourZeroFour} />
        </Switch>
    );
}

export default Router;
