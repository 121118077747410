import React from 'react';

import { getStorage } from '../../utils';
import CONSTANT from './../../constant';
const defaultProfile = require('./../../assets/images/default.jpg')

const ComponentName = (props: any) => {
    const ud = getStorage(CONSTANT.keys.userDetail);
    return (
        <nav className="navbar default-layout-navbar p-0 fixed-top d-flex flex-row">
            <div className="navbar-menu-wrapper d-flex align-items-center flex-fill pr-lg-0">
                
                <div className="text-black flex-fill">
                    <h5 className="my-0">{props.title ? props.title : ''}</h5>
                </div>
                <ul className="navbar-nav navbar-nav-right">
                    <li className="nav-item nav-notification mr-lg-3">
                        
                        <a href="javascript:void(0)" className="nav-link px-3" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="icon"><img src="/assets/images/icons/notification.png" /></div>
                        </a>
                        <div className="notification dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h4 className="my-0">Notifications</h4>
                                {props.notifications &&
                                    <a href="" className="text-primary font-weight-bold h5 my-0">Delete</a>
                                }
                            </div>
                            <ul>
                                {props.notifications && props.notifications.map((item: any, index: any) => {
                                    return (
                                        <li className={item && item.read ? 'd-flex align-content-center align-items-center justify-content-between' : 'active'}>{item.read} <span>{item.date} { item.time }</span></li>
                                        
                                    )
                                })}
                                {!props.notifications&&
                                    <li>No Notifications</li>
                                }
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item nav-profile dropdown">
                        <a href="" className="nav-link d-flex align-items-center" id="profileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="nav-profile-img">
                                {ud.photoURL ?
                                    <img src={ud.photoURL} alt="image" />
                                    : <img src={defaultProfile} alt="image" />}
                            </div>
                            <div className="nav-profile-text">
                                <p className="mb-1">{ud.displayName}</p>
                            </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="profileDropdown">
                            <a href={CONSTANT.url.myProfile} className="dropdown-item">My Profile</a>
                            <a href={CONSTANT.url.logout} className="dropdown-item">Logout</a>
                        </div>
                    </li>
                </ul>
                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
                    data-toggle="offcanvas">
                    <span className="mdi mdi-menu"></span>
                </button>
            </div>
        </nav>
    )
}

export default ComponentName;