import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from "firebase/app";
import queryString from 'query-string';
import $ from 'jquery';

import Loader from './../../component/common/Loader';
import { onChange, validateForm, setStorage } from './../../utils';
import CONSTANT from './../../constant';
import { getVendorDetail } from './../../action/VendorAction';
import { addActivity } from './../../action/ActivityAction';
import { registerUserStepTwo, resendOTP } from './../../action/AuthAction';
import { getVendorInfo } from './../../action/VendorAction';

class RegisterVerification extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const parsed: any = queryString.parse(props.location.search);
        this.state = {
            uid: parsed.i,
            email: '',
            inputOne: { name: 'inputOne', value: '', error: '', isRequired: true },
            inputTwo: { name: 'inputTwo', value: '', error: '', isRequired: true },
            inputThree: { name: 'inputThree', value: '', error: '', isRequired: true },
            inputFour: { name: 'inputFour', value: '', error: '', isRequired: true },
            showLoader: false
        };
    }

    componentDidMount() {
        this.loadKeyFocus();
        this.loadList();
    }

    public render() {
        const { email, inputOne, inputTwo, inputThree, inputFour, showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="container-fluid page-body-wrapper full-page-wrapper login-bg">
                    <div className="row flex-grow justify-content-center">
                        <div className="col-sm-6 loginLeft align-items-center justify-content-center p-0 d-flex">
                            <div className="logo-strip flex-fill">
                                <div className="brand-logo">
                                    <img src="/assets/images/logo_large.png" />
                                    <h2 className="text-white mt-4 mb-0 d-none d-lg-block">The wait is over...</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 loginRight">
                            <form className="justify-content-around">
                                <div className="fhead justify-content-between">
                                    <h2 className="text-white flex-fill">Verification Code</h2>
                                    <h4 className="text-muted mt-2">Enter the OTP we have just sent to your email {email}</h4>
                                </div>
                                <div className="fbody auth-form-light text-left">
                                    <div className="form-group d-flex otp otpInput">
                                        <input
                                            className={inputOne.error.length > 0 ? "form-control border-white is-invalid" : "form-control border-white"}
                                            type="text"
                                            name={inputOne.name}
                                            value={inputOne.value}
                                            onChange={this.onChange}
                                            maxLength={1}
                                        />
                                        <input
                                            className={inputTwo.error.length > 0 ? "form-control border-white is-invalid" : "form-control border-white"}
                                            type="text"
                                            name={inputTwo.name}
                                            value={inputTwo.value}
                                            onChange={this.onChange}
                                            maxLength={1}
                                        />
                                        <input
                                            className={inputThree.error.length > 0 ? "form-control border-white is-invalid" : "form-control border-white"}
                                            type="text"
                                            name={inputThree.name}
                                            value={inputThree.value}
                                            onChange={this.onChange}
                                            maxLength={1}
                                        />
                                        <input
                                            className={inputFour.error.length > 0 ? "form-control border-white is-invalid" : "form-control border-white"}
                                            type="text"
                                            name={inputFour.name}
                                            value={inputFour.value}
                                            onChange={this.onChange}
                                            maxLength={1}
                                        />
                                    </div>
                                    <div className="d-flex clearfix mt-5"></div>
                                    <div className="mt-3 text-center text-lg-left">
                                        <button type="button" className="auth-link font-weight-600 text-primary h4" onClick={this.onResendOTP}>Resend OTP</button>
                                    </div>
                                </div>
                                <div className="ffoot">
                                    <button type="submit" className="btn btn-block btn-primary btn-lg font-weight-bold auth-form-btn" onClick={this.onConfirm}>Confirm</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    loadKeyFocus = () => {
        const self = this;
        $('.otpInput input').keypress(function () {
            $(self).next().focus();
        }).keyup(function (e: any) {
            if (e.keyCode == 8) {
                $(self).prev().focus();
            }
        });
    }

    loadList = () => {
        this.setState({ showLoader: true });
        const { uid } = this.state;
        getVendorInfo(uid).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                this.setState({ email: res.data.email });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false, list: [], count: 0 });
        });
    }

    private onResendOTP = async (e: any) => {

        const { email } = this.state;
        const model = {
            email,
            validateType: "verifyEmail",
        };
        this.setState({ showLoader: true });
        resendOTP(model).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                toast.success(res.message);
            }
            if (res.status === 400) {
                toast.error(res.message);
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });

    }

    private onConfirm = async (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { uid, inputOne, inputTwo, inputThree, inputFour, showLoader } = this.state;
            const model = {
                uid: uid,
                validateType: "verifyEmail",
                otp: `${inputOne.value}${inputTwo.value}${inputThree.value}${inputFour.value}`
            };
            this.setState({ showLoader: true });
            registerUserStepTwo(model).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 200) {
                    setStorage(CONSTANT.keys.token, res.data.token);
                    setStorage(CONSTANT.keys.userDetail, { uid: res.data.uid, displayName: res.data.displayName, email: res.data.email, photoURL: res.data.photoURL });
                    this.props.history.push(CONSTANT.url.buyProduct);
                }
                if (res.status === 400) {
                    toast.error(res.message);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        }
    }

    private submitForm = async (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const st = this.state;
            const model = {
                email: st.email.value,
                password: st.password.value
            };
            this.setState({ showLoader: true });
            firebase.app().auth().signInWithEmailAndPassword(model.email, model.password).then(async (result: any) => {
                if (result) {
                    const userDetail = await getVendorDetail({ uid: result.user.uid });
                    if (userDetail && userDetail.data.role === "vendor") {
                        await addActivity({ activityName: 'Logged in test', userId: result.user.uid });
                        this.setState({ showLoader: false });
                        setStorage(CONSTANT.keys.token, userDetail.data.token);
                        setStorage(CONSTANT.keys.userDetail, { uid: userDetail.data.uid, displayName: userDetail.data.displayName, email: userDetail.data.email, photoURL: userDetail.data.photoURL });
                        this.props.history.push(CONSTANT.url.buyProduct);
                    } else {
                        this.setState({ showLoader: false });
                        toast.error("You are not authorized to login");
                    }
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
                if (error && error.code === "auth/user-not-found") {
                    toast.error(error.message);
                }
                if (error && error.code === "auth/wrong-password") {
                    toast.error(error.message);
                }
            });
        }
    }
}

export default RegisterVerification;