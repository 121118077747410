import * as React from 'react';
import NumberFormat from 'react-number-format';
import firebase from "firebase/app";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

import Header from './../../component/common/Header';
import Sidebar from './../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Loader from './../../component/common/Loader';
import { getVendorDetail, changePassword } from './../../action/VendorAction';
import CONSTANT from '../../constant';
import { onChange, validateForm } from './../../utils';

class ChangePassword extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            uid: props.match.params.id,
            showLoader: false,
            detail: undefined,
            oldPassword: { name: 'oldPassword', value: '', error: '', isRequired: true },
            newPassword: { name: 'newPassword', value: '', error: '', isRequired: true },
            cnfPassword: { name: 'cnfPassword', value: '', error: '', isRequired: true },
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader, oldPassword, newPassword, cnfPassword } = this.state;
        return (
            <React.Fragment>
                <div className="container-scroller">
                    <Header title='Change Password' />
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar />
                        <div className="main-panel">
                            <div className="content-wrapper">

                                <form className="row px-3 mb-5 pt-4 pt-lg-0" onSubmit={this.submitForm}>
                                    <div className="form-group col-sm-12 mt-0 mt-lg-3">
                                        <label>Old Password</label>
                                        <input
                                            className={oldPassword.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            type="password"
                                            name={oldPassword.name}
                                            value={oldPassword.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group col-sm-12 mt-0 mt-lg-3">
                                        <label>New Password</label>
                                        <input
                                            className={newPassword.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            type="password"
                                            name={newPassword.name}
                                            value={newPassword.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group col-sm-12 mt-0 mt-lg-3">
                                        <label>Confirm New Password</label>
                                        <input
                                            className={cnfPassword.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            type="password"
                                            name={cnfPassword.name}
                                            value={cnfPassword.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="col-sm-12 text-right mt-2 d-flex align-items-center justify-content-center justify-content-lg-end">
                                        <button type="submit" className="btn btn-primary px-4 min-width"><b>Save</b></button>
                                    </div>
                                </form>
                            </div>
                            <div id="mask"></div>
                            <Footer />
                        </div>

                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    loadList = () => {
        const { uid } = this.state;

        this.setState({ showLoader: true });
        getVendorDetail({ uid }).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                this.setState({ detail: res.data });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
            console.log(error);
        });
    }

    private submitForm = async (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { uid, detail, email, oldPassword, newPassword, cnfPassword } = this.state;

            if (newPassword.value !== cnfPassword.value) return toast.error("New password and confirm password doesn't match!");

            const model = {
                uid: uid,
                password: newPassword.value
            }
            this.setState({ showLoader: true });
            firebase.app().auth().signInWithEmailAndPassword(detail.email, oldPassword.value).then(async (result: any) => {
                this.setState({ showLoader: false });
                if (result) {
                    const chRes = await changePassword(model);
                    if (chRes.status === 200) {
                        toast.success(chRes.message);
                        setTimeout(() => {
                            this.props.history.push(CONSTANT.url.myProfile);
                        }, 1000)
                    }
                    this.setState({ showLoader: false, });
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
                if (error.code === "auth/wrong-password") {
                    toast.error(error.message);
                }
            });
        }
    }

}

export default ChangePassword;