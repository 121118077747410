import * as React from 'react';
import { Link } from 'react-router-dom';

import Header from './../../component/common/Header';
import Sidebar from './../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Loader from './../../component/common/Loader';

import { getVendorDetail } from './../../action/VendorAction';
import CONSTANT from '../../constant';
import { getStorage } from '../../utils';
const defaultProfile = require('./../../assets/images/default.jpg')

class Profile extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            id: undefined,
            showLoader: false,
            detail: undefined,
            subscribedPlan: undefined
        }
    }

    componentDidMount() {
        const ud = getStorage(CONSTANT.keys.userDetail);
        this.setState({ id: ud.uid }, () => {
            this.loadList();
        });
    }

    public render() {
        const { detail, id, showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="container-scroller">
                    <Header title='My Profile' />
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar />
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="profile-header px-3">
                                    <div className="header-bg" style={{ backgroundImage: `${detail && detail.profileCoverURL ? `url(${detail.profileCoverURL})` : "url('./../../assets/images/profile-header.png')"}` }}></div>
                                    <div className="profile-pic">
                                        <div className="tumbnail">
                                            {detail &&
                                                <img src={detail.photoURL ? detail.photoURL : defaultProfile} height="100" width="100" alt="avatar" />
                                            }
                                        </div>
                                        <div className="brief">
                                            <strong>{detail ? detail.displayName : ''}</strong>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <Link className="btn btn-white-transparent" to={CONSTANT.url.changePassword.replace(':id', id)}>Change Password</Link>
                                    </div>
                                </div>
                                <div className="row px-3 profile-card mb-5 mt-3">
                                    <div className="col-sm-6 col-lg-4 mt-4">
                                        <div className="card xs-shadow border-radius-xs mb-0 mb-lg-4">
                                            <div className="card-body px-3 py-3">
                                                Trading Name
                                                <h4 className="text-black mt-1 mb-0">{detail ? detail.tradingName : ''}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mt-4">
                                        <div className="card xs-shadow border-radius-xs mb-0 mb-lg-4">
                                            <div className="card-body px-3 py-3">
                                                Stud Name (s)
                                                <h4 className="text-black mt-1 mb-0">{detail ? detail.studName : ''}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mt-4">
                                        <div className="card xs-shadow border-radius-xs mb-0 mb-lg-4">
                                            <div className="card-body px-3 py-3">
                                                Phone no
                                                <h4 className="text-black mt-1 mb-0">{detail ? detail.phone : ''}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mt-4">
                                        <div className="card xs-shadow border-radius-xs mb-0 mb-lg-4">
                                            <div className="card-body px-3 py-3">
                                                Email
                                                <h4 className="text-black mt-1 mb-0">{detail ? detail.email : ''}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mt-4">
                                        <div className="card xs-shadow border-radius-xs mb-0 mb-lg-4">
                                            <div className="card-body px-3 py-3">
                                                Website
                                                <h4 className="text-black mt-1 mb-0">{detail ? detail.website : '-'}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mt-4">
                                        <div className="card xs-shadow border-radius-xs mb-0 mb-lg-4">
                                            <div className="card-body px-3 py-3">
                                                Address
                                                <h4 className="text-black mt-1 mb-0">{detail ? detail.address : ''}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 text-right mt-4">
                                        <Link className="btn btn-primary px-5 d-block d-lg-inline" to={CONSTANT.url.editProfile.replace(':id', id)}><b className="mx-3">Edit
                                        Profile</b></Link>
                                    </div>
                                </div>
                            </div>
                            <div id="mask"></div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    loadList = () => {
        const { id } = this.state;
        this.setState({ showLoader: true });
        getVendorDetail({ uid: id }).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 200) {
                this.setState({ detail: res.data });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

}

export default Profile;