import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './../../component/common/Header';
import Sidebar from './../../component/common/Sidebar';
import Loader from '../../component/common/Loader';
import AddCatalogueDetail from './../../component/catalogue/Add';
import { createCatalogue } from './../../action/CatalogueAction';
import CONSTANT from '../../constant';

class AddCatalogue extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
        }
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                <div className="container-scroller">
                    <Header title='My Catalogues' />
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar />
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href={CONSTANT.url.myCatalogues}>My Catalogues</a></li>
                                        <li className="breadcrumb-item active"><a href={CONSTANT.url.addCatalogue}>New Catalogue</a></li>
                                    </ol>
                                </nav>
                                <AddCatalogueDetail
                                    onSubmit={this.onSubmit}
                                />
                            </div>
                            <div id="mask"></div>
                            <footer className="footer">
                                <div className="text-center text-muted">
                                    Copyright &copy; 2020 <span className="text-default">Studbooks</span>. All rights reserved.
					            </div>
                            </footer>
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    onSubmit = (model: any) => {
        this.setState({ showLoader: true });
        createCatalogue(model).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.status === 201) {
                toast.success("Catalogue Successfully added!");
                this.props.history.push(CONSTANT.url.myCatalogues);
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }
}

export default AddCatalogue;
