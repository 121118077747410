import * as axios from 'axios';

export const registerUser = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/auth/users`;
    return axios.post(url, model)
        .then((response: any) => {
            return response.data;
        }).catch((err: any) => {
            throw err.response;
        });
}

export const registerUserStepTwo = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/auth/vendor-step-two`;
    return axios.post(url, model)
        .then((response: any) => {
            return response.data;
        }).catch((err: any) => {
            throw err.response;
        });
}

export const forgotPassword = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/auth/vendor/forgot-password`;
    return axios.post(url, model)
        .then((response: any) => {
            return response.data;
        }).catch((err: any) => {
            throw err.response;
        });
}

export const resendOTP = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/auth/resend-otp`;
    return axios.post(url, model)
        .then((response: any) => {
            return response.data;
        }).catch((err: any) => {
            throw err.response;
        });
}

export const validateForgotOTP = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/auth/vendor/validate-otp`;
    return axios.post(url, model)
        .then((response: any) => {
            return response.data;
        }).catch((err: any) => {
            throw err.response;
        });
}

export const resetPassword = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/auth/vendor/reset-password`;
    return axios.post(url, model)
        .then((response: any) => {
            return response.data;
        }).catch((err: any) => {
            throw err.response;
        });
}