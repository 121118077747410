import * as React from 'react';
import firebase from "firebase/app";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

import Loader from '../../component/common/Loader';
import { onChange, validateForm, setOptions } from './../../utils';
import CONSTANT from '../../constant';
import { getCategoryList, getAnimalTypeList, getStudnameList, getBreedWithAnimal, addStudName, updateAnimalStud } from './../../action/CatalogueAction';
import { getCountry, getState, getCity } from './../../action/MasterAction';
import ModalWindow from '../../component/common/ModalWindow';
import StudDetail from '../../component/catalogue/StudDetail';
import CONFIG from './../../config';

const camImg = require('./../../assets/images/icons/image.png');

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        const saleTypeDrop = [
            { id: 1, name: 'On Property (Open Cry)', value: 'On Property (Open Cry)' },
            { id: 2, name: 'On Property (Helmsman)', value: 'On Property (Helmsman)' },
            { id: 3, name: 'On Property (Online)', value: 'On Property (Online)' },
            { id: 4, name: 'Online (Timed Auction)', value: 'Online (Timed Auction)' },
            { id: 5, name: 'Multi-Vendor (Open Cry)', value: 'Multi-Vendor (Open Cry)' },
            { id: 6, name: 'Multi-Vendor (Helmsman)', value: 'Multi-Vendor (Helmsman)' },
            { id: 7, name: 'Off Farm (Open Cry)', value: 'Off Farm (Open Cry)' },
            { id: 8, name: 'Off Farm (Online/Helmsman)', value: 'Off Farm (Online/Helmsman)' },
        ]
        this.state = {
            id: props.id,
            detail,
            title: { name: 'title', value: detail && detail.title ? detail.title : '', error: '', isRequired: true },
            dateRangeFrom: { name: 'dateRangeFrom', value: detail && detail.rangeFromDate ? detail.rangeFromDate : '', error: '', isRequired: true },
            dateRangeTo: { name: 'dateRangeTo', value: detail && detail.rangeToDate ? detail.rangeToDate : '', error: '', isRequired: true },
            catalogueStatus: { name: 'catalogueStatus', value: detail && detail.status ? detail.status : '', error: '', isRequired: true },

            saleDateFrom: { name: 'saleDateFrom', value: detail && detail.saleFromDate ? detail.saleFromDate : '', error: '', isRequired: true },
            saleDateTo: { name: 'saleDateTo', value: detail && detail.saleToDate ? detail.saleToDate : '', error: '', isRequired: true },
            saleType: { name: 'saleType', value: '', error: '', isRequired: true, options: saleTypeDrop },
            categoryId: { name: 'categoryId', value: detail && detail.categoryId ? detail.categoryId : '', error: '', isRequired: true, options: [] },

            saleLocation: { name: 'saleLocation', value: detail && detail.saleLocation ? detail.saleLocation : '', error: '', isRequired: false },
            country: { name: 'country', value: detail && detail.country ? detail.country : '', error: '', isRequired: true, options: [] },
            state: { name: 'state', value: detail && detail.state ? detail.state : '', error: '', isRequired: true, options: [] },
            city: { name: 'city', value: detail && detail.city ? detail.city : '', error: '', isRequired: false, options: [] },
            postCode: { name: 'postCode', value: detail && detail.postCode ? detail.postCode : '', error: '', isRequired: true, options: [] },

            animalType: { name: 'animalType', value: '', error: '', isRequired: false, options: [] },
            studName: { name: 'studName', value: '', error: '', isRequired: false, options: [] },
            breed: { name: 'breed', value: '', error: '', isRequired: false, options: [] },
            number: { name: 'number', value: '', error: '', isRequired: false, options: [] },

            updates: { name: 'updates', value: '', error: '', isRequired: false, options: [] },

            studData: detail && detail.studDetail ? detail.studDetail : [],

            thumbnailUrl: detail && detail.thumbnailImage ? detail.thumbnailImage : undefined,
            catalogueUrl: detail && detail.cataloguePdf ? detail.cataloguePdf : undefined,
            thumbnailFileName: detail && detail.thumbnailFileName ? detail.thumbnailFileName : undefined,
            cataloguePdfFileName: detail && detail.cataloguePdfFileName ? detail.cataloguePdfFileName : undefined,
            showLoader: false,
            isLocationSelected: detail && detail.latitude && detail.longitude ? true : false,
            latitude: detail && detail.latitude ? detail.latitude : undefined,
            longitude: detail && detail.latitude ? detail.longitude : undefined,
            studDetail: undefined
        }
    }

    componentDidMount() {
        const { detail, saleType } = this.state;
        if (detail && detail.saleType) {
            let temp: any = [];
            let saleTemp: any = [];
            temp = detail.saleType.split(", ");
            for (let a = 0; a < temp.length; a++) {
                temp[a] = temp[a]
            }
            const filterSType = saleType.options.map((i: any) => {
                for (let a = 0; a < temp.length; a++) {
                    if (i.name === temp[a]) {
                        saleTemp.push(i)
                    }
                }

                return i;
            });
            this.setState({
                saleType: { ...this.state.saleType, value: saleTemp },
            });

        }
        this.loadList();
    }

    public render() {
        const {
            id, title, dateRangeFrom, dateRangeTo, catalogueStatus, saleDateFrom, saleDateTo, saleType, categoryId, saleLocation,
            country, state, city, animalType, studName, breed, number, studData, showLoader, thumbnailUrl, catalogueUrl, updates,
            postCode, isLocationSelected, location, showStudModal, studDetail
        } = this.state;
        const url = id ? CONSTANT.url.catalogueUpdates.replace(':id', id) : '';
        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit}>
                    <div className="page-header">
                        <div className="flex-fill mr-2 pr-1">
                            <input
                                type="text"
                                className={title.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                placeholder="Catalogue Title"
                                name={title.name}
                                value={title.value}
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="add">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-sm-4">
                            <div className="card">
                                <div className="card-body px-2 py-2">
                                    <div className=" bg-light-gray px-3 py-3">
                                        <div className="text-center">
                                            {thumbnailUrl ?
                                                <div className="addImage d-table m-auto">
                                                    <img src={thumbnailUrl} alt="image" height="250" width="300" />
                                                </div>
                                                : <div className="addImage d-table m-auto">
                                                    <img src={camImg} alt="image" />
                                                    <h6 className="mt-1">Add a thumbnail image</h6>
                                                    <input type="file" accept="image/*" onChange={this.onThumbnailAdded} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div className="card">
                                <div className="card-body px-2 py-2">
                                    <div className="clearfix">
                                        {catalogueUrl ?
                                            <embed src={catalogueUrl} type="application/pdf" width="100%" height="250px" />
                                            :
                                            <React.Fragment>
                                                <div className="col-sm-7">
                                                    <div className="dropFile">
                                                        <img src="/assets/images/icons/upload.png" alt="upload" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-5 text-center py-2">
                                                    <h6>Drag & drop catalogue pdf</h6>
                                                    <h5 className="pt-1 mb-3 text-gray">or</h5>
                                                    <input type="file" accept="application/pdf" className="btn btn-primary btn-md" onChange={this.onFilesAdded} />
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-sm-12">
                            <div className="card strip-form mb-3">
                                <div className="card-body px-2 py-3">
                                    <div className="form-inline row mx-0 justify-content-between">
                                        <div className="form-group col-sm-7">
                                            <label className="my-0 col-sm-3 px-0 justify-content-start font-weight-600">Catalogue Live Date</label>
                                            <div className="d-flex flex-fill">
                                                <input
                                                    type="date"
                                                    className={dateRangeFrom.error.length > 0 ? "col form-control mr-3 col-sm-6 calendar flex-fill form-control is-invalid" : "col form-control mr-3 col-sm-6 calendar flex-fill form-control"}
                                                    placeholder="From"
                                                    name={dateRangeFrom.name}
                                                    value={dateRangeFrom.value}
                                                    onChange={this.onChange}
                                                />
                                                <input
                                                    type="date"
                                                    className={dateRangeTo.error.length > 0 ? "col form-control col-sm-6 calendar flex-fill form-control is-invalid" : "col form-control col-sm-6 calendar flex-fill form-control"}
                                                    placeholder="To"
                                                    name={dateRangeTo.name}
                                                    value={dateRangeTo.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-sm-4 d-flex justify-content-end align-items-center">
                                            <label className="my-0 col-sm-5 px-0 justify-content-start font-weight-600">Catalogue Status</label>
                                            <select
                                                className={catalogueStatus.error.length > 0 ? "form-control col-sm-6 is-invalid" : "form-control col-sm-6"}
                                                name={catalogueStatus.name}
                                                value={catalogueStatus.value}
                                                onChange={this.onChange}
                                            >
                                                <option value="">Select Status</option>
                                                <option value="Draft">Draft</option>
                                                <option value="Live">Live</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card strip-form mb-3">
                                <div className="card-body px-2 py-3">
                                    <div className="form-inline row mx-0 justify-content-between">
                                        <div className="form-group col-sm-6">
                                            <label className="my-0 col-sm-2 px-0 justify-content-start font-weight-600">Sale Date</label>
                                            <div className="d-flex flex-fill">
                                                <input
                                                    type="date"
                                                    className={saleDateFrom.error.length > 0 ? "col form-control mr-3 col-sm-6 calendar flex-fill form-control is-invalid" : "col form-control mr-3 col-sm-6 calendar flex-fill form-control"}
                                                    placeholder="From"
                                                    name={saleDateFrom.name}
                                                    value={saleDateFrom.value}
                                                    onChange={this.onChange}
                                                />
                                                <input
                                                    type="date"
                                                    className={saleDateTo.error.length > 0 ? "col form-control mr-3 col-sm-6 calendar flex-fill form-control is-invalid" : "col form-control mr-3 col-sm-6 calendar flex-fill form-control"}
                                                    placeholder="To"
                                                    name={saleDateTo.name}
                                                    value={saleDateTo.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-sm-3 d-flex justify-content-end align-items-center">
                                            <label className="col-sm-4 justify-content-start font-weight-600">Sale Type</label>
                                            <div className="col-sm-7">
                                                <Typeahead
                                                    id="ta-permission-ids"
                                                    allowNew={false}
                                                    labelKey={(option: any) => `${option.name}`}
                                                    name={saleType.name}
                                                    selected={saleType.value}
                                                    multiple={true}
                                                    options={saleType.options}
                                                    onChange={(e: any) => this.typeaheadOnChange(saleType.name, e)}
                                                    placeholder="Select sale type"
                                                    isInvalid={saleType.error.length > 0}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-sm-3 d-flex justify-content-end align-items-center">
                                            <label className="my-0 col-sm-5 px-0 justify-content-start font-weight-600">Category</label>
                                            <select
                                                className={categoryId.error.length > 0 ? "form-control col-sm-6 is-invalid" : "form-control col-sm-6"}
                                                name={categoryId.name}
                                                value={categoryId.value}
                                                onChange={this.onChange}
                                            >
                                                <option value="">Select category</option>
                                                {
                                                    categoryId.options.map(function (item: any, ind: number) {
                                                        return (
                                                            <option key={ind} id={item.id} value={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card strip-form mb-4">
                                <div className="card-body px-2 py-3">
                                    <div className="form-inline row mx-0 justify-content-between">
                                        <div className="form-group col-sm-4">
                                            <label className="my-0 col-sm-2 px-0 justify-content-start font-weight-600">Sale Location</label>
                                            <GooglePlacesAutocomplete
                                                apiKey={CONFIG.placeAPIKey}
                                                selectProps={{
                                                    defaultInputValue: saleLocation.value,
                                                    onChange: this.onChangeAuto,
                                                    className: "flex-fill"
                                                }}
                                            />
                                        </div>
                                        <div className="form-group col-sm-8 pl-lg-0">
                                            <div className="row flex-fill col-sm-12 pr-0 pl-0 mx-0">
                                                <div className="flex-fill d-flex pr-1">
                                                    <select
                                                        className={country.error.length > 0 ? "form-control flex-fill is-invalid" : "form-control flex-fill"}
                                                        name={country.name}
                                                        value={country.value}
                                                        onChange={this.onChange}
                                                    >
                                                        <option value="">Select country</option>
                                                        {
                                                            country.options.map(function (item: any, ind: number) {
                                                                return (
                                                                    <option key={ind} id={item.id} value={item.name}>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="flex-fill d-flex pr-1">
                                                    <select
                                                        className={state.error.length > 0 ? "form-control flex-fill is-invalid" : "form-control flex-fill"}
                                                        name={state.name}
                                                        value={state.value}
                                                        onChange={this.onChange}
                                                    >
                                                        <option value="">Select state</option>
                                                        {
                                                            state.options.map(function (item: any, ind: number) {
                                                                return (
                                                                    <option key={ind} id={item.id} value={item.name}>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="flex-fill d-flex pl-1">
                                                    <input
                                                        type="text"
                                                        className={postCode.error.length > 0 ? "form-control flex-fill is-invalid" : "form-control flex-fill"}
                                                        placeholder="Enter post code"
                                                        name={postCode.name}
                                                        value={postCode.value}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h6>Stud Details <button type="button" className="text-primary text-decoration ml-2" onClick={this.onAddNewStud}>Add new stud</button></h6>
                            <div className="card strip-form mb-3 pt-2">
                                <div className="card-body px-2 py-3">
                                    <div className="table-responsive">
                                        <table className="table no-border">
                                            <thead>
                                                <tr>
                                                    <th className="justify-content-start pb-2"><b>Stud Name</b></th>
                                                    <th className="justify-content-start pb-2"><b>Animal Type</b></th>
                                                    <th className="justify-content-start pb-2"><b>Breed</b></th>
                                                    <th className="justify-content-start pb-2"><b>Lots</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    studData !== undefined && studData.map((item: any, addIndex: number) => {
                                                        return (
                                                            <React.Fragment key={addIndex}>
                                                                <tr>
                                                                    <td>
                                                                        <div className="form-group mb-2">
                                                                            <input disabled className="form-control no-border min-width-150" value={item.studName} />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group mb-2">
                                                                            <input disabled className="form-control no-border min-width-150" value={item.animalType} />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group mb-2">
                                                                            <input disabled className="form-control no-border min-width-150" value={item.breed} />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group mb-2">
                                                                            <input disabled className="form-control no-border min-width-150" value={item.number} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }

                                                <tr>
                                                    <td>
                                                        <div className="form-group mb-2">

                                                            <select
                                                                className={studName.error.length > 0 ? "form-control min-width-150 is-invalid" : "form-control min-width-150"}
                                                                name={studName.name}
                                                                value={studName.value}
                                                                onChange={this.onChange}
                                                            >
                                                                <option value="">Select stud name</option>
                                                                {
                                                                    studName.options.map(function (item: any, ind: number) {
                                                                        return (
                                                                            <option key={ind} id={item.id} value={item.name}>{item.studName}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group mb-2">
                                                            <select
                                                                className={animalType.error.length > 0 ? "form-control min-width-150 is-invalid" : "form-control min-width-150"}
                                                                name={animalType.name}
                                                                value={animalType.value}
                                                                onChange={this.onChange}
                                                            >
                                                                <option value="">Select animal type</option>
                                                                {
                                                                    animalType.options.map(function (item: any, ind: number) {
                                                                        return (
                                                                            <option key={ind} id={item.id} value={item.name}>{item.animalType}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group mb-2">
                                                            <select
                                                                className={breed.error.length > 0 ? "form-control min-width-150 is-invalid" : "form-control min-width-150"}
                                                                name={breed.name}
                                                                value={breed.value}
                                                                onChange={this.onChange}
                                                            >
                                                                <option value="">Select breed</option>
                                                                {
                                                                    breed.options.map(function (item: any, ind: number) {
                                                                        return (
                                                                            <option key={ind} id={item.id} value={item.name}>{item.breed}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group mb-2">
                                                            <NumberFormat
                                                                className={number.error.length > 0 ? "form-control min-width-150 is-invalid" : "form-control min-width-150"}
                                                                placeholder="Enter number"
                                                                name={number.name}
                                                                maxLength={5}
                                                                allowNegative={false}
                                                                value={number.value}
                                                                allowLeadingZeros={true}
                                                                onChange={this.onChange}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="text-right col-sm-12">
                                        <button type="button" className="h5 text-primary text-decoration" onClick={this.onAddStud}>Add</button>
                                    </div>
                                </div>
                            </div>
                            {id &&
                                <div className="card strip-form mb-3">
                                    <div className="card-body px-2 py-3">
                                        <div className="form-inline row mx-0 justify-content-between">
                                            <div className="form-group col-sm-12">
                                                <label className="my-0 px-0 pr-1 justify-content-start font-weight-600">Sale Notifications</label>
                                                <input
                                                    type="text"
                                                    className={updates.error.length > 0 ? "form-control flex-fill is-invalid" : "form-control flex-fill"}
                                                    placeholder="Write an update here eg. Sale starts tomorrow, Supplementary Sheet available now..."
                                                    name={updates.name}
                                                    value={updates.value}
                                                    onChange={this.onChange}
                                                />
                                                <Link to={url} className="h6 text-primary text-decoration ml-3">View All</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                </form>

                {
                    showLoader && <Loader />
                }
                {
                    showStudModal && <ModalWindow
                        className="modal-md "
                        title="Add New Stud"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <StudDetail
                            onSubmit={this.onSubmitStud}
                            onCancel={this.onCancel}
                            detail={studDetail}
                        />
                    </ModalWindow>
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;

        if (name === this.state.country.name) {
            let id = e.target[e.target.selectedIndex].id
            this.getStateList(id);
        }

        if (name === this.state.state.name) {
            let id = e.target[e.target.selectedIndex].id
        }

        if (name === this.state.animalType.name) {
            let id = e.target[e.target.selectedIndex].id
            this.getBreedList(id);
        }

        onChange(this, name, value);
    }

    onChangeAuto = (e: any) => {
        geocodeByAddress(e.label)
            .then((results: any) => getLatLng(results[0]))
            .then(({ lat, lng }: any) => {
                this.setState({
                    isLocationSelected: true,
                    latitude: lat,
                    longitude: lng,
                    saleLocation: { ...this.state.saleLocation, value: e.label },
                    address: e.label
                });
            });
    }

    typeaheadOnChange = (name: string, e: any) => {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        onChange(this, name, value);
    }

    loadList = () => {
        const { id } = this.state;
        this.setState({ showLoader: true });
        getCategoryList().then((res: any) => {
            if (res.status === 200) {
                setOptions(this, this.state.categoryId.name, res.data);
                this.setState({ showLoader: false });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
        getCountry().then((res: any) => {
            if (res.status === 200) {
                setOptions(this, this.state.country.name, res.data);
                const filterMapCountry = res.data.filter((i: any) => i.name === this.state.country.value);
                if (filterMapCountry.length > 0) {
                    this.getStateList(filterMapCountry[0].id);
                }
                this.setState({ showLoader: false });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
        getStudnameList().then((res: any) => {
            if (res.status === 200) {
                setOptions(this, this.state.studName.name, res.data);
                this.setState({ showLoader: false });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
        getAnimalTypeList().then((res: any) => {
            if (res.status === 200) {
                setOptions(this, this.state.animalType.name, res.data);
                this.setState({ showLoader: false });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    getStateList = (id: any) => {
        this.setState({ showLoader: true });
        getState(id).then((res: any) => {
            if (res.status === 200) {
                this.setState({ showLoader: false });
                setOptions(this, this.state.state.name, res.data);

            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    getCityList = (id: any) => {
        this.setState({ showLoader: true });
        getCity(id).then((res: any) => {
            if (res.status === 200) {
                setOptions(this, this.state.city.name, res.data);
                this.setState({ showLoader: false });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }

    getBreedList = (animalTypeId: any) => {
        this.setState({ showLoader: true });
        getBreedWithAnimal(animalTypeId).then((res: any) => {
            if (res.status === 200) {
                const breedList = res.data.length > 0 ? res.data[0].breeds : [];
                setOptions(this, this.state.breed.name, breedList);
                this.setState({ showLoader: false });
            }
        }).catch((error: any) => {
            this.setState({ showLoader: false });
        });
    }


    onThumbnailAdded = (e: any) => {
        let file = e.target.files[0];
        if (file) {
            this.handleThumbnailUpload(file);
        }
        e.target.value = null;
    }

    onFilesAdded = (e: any) => {
        let file = e.target.files[0];
        this.handleUpload(file);
    }

    handleThumbnailUpload = (file: any) => {
        this.setState({ showLoader: true });
        const extArray = file.name.split('.');
        const ext = extArray[extArray.length - 1];
        const originalFilename = extArray[0];
        let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
        const fileDirectory = `${CONSTANT.fileDirectory.catalogueThumbnail}/${fileName}`;

        const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

        uploadTask.on("state_changed", (snapshot: any) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
        },
            (error: any) => {
                console.log(error);
            },
            () => {
                firebase.app().storage()
                    .ref(CONSTANT.fileDirectory.catalogueThumbnail)
                    .child(fileName)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ thumbnailUrl: url, thumbnailFileName: fileName, showLoader: false });
                    });
            }
        );
    };

    onThumbnailDelete = () => {
        this.setState({ showLoader: true });
        firebase.app().storage()
            .ref(CONSTANT.fileDirectory.catalogueThumbnail)
            .child(this.state.thumbnailFileName)
            .delete()
            .then(url => {
                this.setState({ thumbnailFileName: "", thumbnailUrl: "", showLoader: false });
            });
    }

    handleUpload = (file: any) => {
        this.setState({ showLoader: true });

        const extArray = file.name.split('.');
        const ext = extArray[extArray.length - 1];
        const originalFilename = extArray[0];
        let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
        const fileDirectory = `${CONSTANT.fileDirectory.catalogues}/${fileName}`;

        const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

        uploadTask.on("state_changed", (snapshot: any) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
        },
            (error: any) => {
                console.log(error);
            },
            () => {
                firebase.app().storage()
                    .ref(CONSTANT.fileDirectory.catalogues)
                    .child(fileName)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ catalogueUrl: url, cataloguePdfFileName: fileName, showLoader: false });
                    });
            }
        );
    };

    onPDFDelete = () => {
        this.setState({ showLoader: true });
        firebase.app().storage()
            .ref(CONSTANT.fileDirectory.catalogues)
            .child(this.state.cataloguePdfFileName)
            .delete()
            .then(url => {
                this.setState({ cataloguePdfFileName: "", catalogueUrl: "", showLoader: false });
            });
    }

    onAddStud = async () => {
        let isValidForm = false;
        this.setState({
            studName: { ...this.state.studName, isRequired: true },
            animalType: { ...this.state.animalType, isRequired: true },
            breed: { ...this.state.breed, isRequired: true },
            number: { ...this.state.number, isRequired: true }
        });
        isValidForm = await validateForm(this)
        if (await validateForm(this)) {
            const { animalType, studName, breed, number, studData } = this.state;
            let studArr = studData;
            const newObj = {
                animalType: animalType.value,
                studName: studName.value,
                breed: breed.value,
                number: parseInt(number.value, 10),
            }
            studArr.push(newObj);
            this.setState({ studData: studArr });
            this.setState({
                studName: { ...this.state.studName, value: '' },
                animalType: { ...this.state.animalType, value: '' },
                breed: { ...this.state.breed, value: '' },
                number: { ...this.state.number, value: '' }
            });
        }

    }

    onAddDelete = (index: any) => {
        const { studData } = this.state;
        let modArr = studData;
        modArr.splice(index, 1);
        this.setState({ studData: modArr });
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        this.setState({
            studName: { ...this.state.studName, isRequired: false },
            animalType: { ...this.state.animalType, isRequired: false },
            breed: { ...this.state.breed, isRequired: false },
            number: { ...this.state.number, isRequired: false }
        }, () => {

            if (validateForm(this)) {
                const { title, dateRangeFrom, dateRangeTo, catalogueStatus, saleDateFrom, saleDateTo, saleType, categoryId,
                    saleLocation, country, state, city, animalType, studName, breed, number, studData, thumbnailUrl, catalogueUrl,
                    updates, thumbnailFileName, cataloguePdfFileName, postCode, isLocationSelected, latitude, longitude
                } = this.state;

                if (!isLocationSelected) return toast.error("Please select sale location.");
                if (!thumbnailUrl) return toast.error("Please upload thumbnail image.");
                if (!catalogueUrl) return toast.error("Please upload catalogue pdf.");

                const filertCat = categoryId.options.filter((i: any) => i.id == categoryId.value);
                let saleT = saleType.value ? saleType.value.map((i: any) => i.name) : '';

                const model: any = {
                    categoryName: filertCat[0].name,
                    categoryImage: filertCat[0].image,
                    categoryId: filertCat[0].id,
                    catelogue: {
                        title: title.value,
                        thumbnailImage: thumbnailUrl,
                        cataloguePdf: catalogueUrl,
                        rangeFromDate: moment(dateRangeFrom.value).format("YYYY-MM-DD"),
                        rangeToDate: moment(dateRangeTo.value).format("YYYY-MM-DD"),
                        status: catalogueStatus.value,
                        saleFromDate: moment(saleDateFrom.value).format("YYYY-MM-DD"),
                        saleToDate: moment(saleDateTo.value).format("YYYY-MM-DD"),
                        saleType: saleT ? saleT.join(', ') : '',
                        saleLocation: saleLocation.value,
                        country: country.value,
                        state: state.value,
                        city: city.value,
                        postCode: postCode.value,
                        latitude: latitude,
                        longitude: longitude,
                        studDetail: studData,
                        isApproved: false,
                        thumbnailFileName,
                        cataloguePdfFileName,
                    }
                };
                if (this.state.id) {
                    model.id = this.state.id;
                    model.updates = updates.value;
                }
                this.props.onSubmit(model);
            }
        });
    }

    onAddNewStud = () => {
        this.setState({ showStudModal: true });
    }

    onCancel = () => {
        this.setState({ showStudModal: false });
    }

    onSubmitStud = (reqObj: any) => {
        this.onCancel();
        this.setState({ showLoader: true });
        if (reqObj.id) {
            updateAnimalStud(reqObj).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 200) {
                    toast.success(res.message);
                    this.loadList();
                    this.onCancel();
                }
                if (res.status === 400) {
                    toast.error(res.message);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        } else {
            addStudName(reqObj).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status === 201) {
                    toast.success("New stud added.");
                    this.loadList();
                    this.onCancel();
                }
                if (res.status === 400) {
                    toast.error(res.message);
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
            });
        }
    }
}

export default AddComponent;